import { Grid, Flex, Badge, Divider, Button, Input } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import { IconExternalLink } from '@tabler/icons-react';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import { Link } from 'react-router-dom';
import theme from 'theme';

/**
 * A functional component that displays a server hardware panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.id] - The unique identifier.
 * @param {string} [props.model] - The model name of the CPU.
 * @param {string} [props.cores] - The CPU core configuration.
 * @param {string} [props.frequency] - The CPU frequency in MHz.
 * @param {string} [props.ram] - The amount of RAM in GB.
 * @param {string} [props.swap] - The amount of swap memory in GB.
 * @return {JSX.Element} The server hardware panel component.
 */
const SystemPanel = ({
  id = 1,
  hostname = '500km.sk',
  osRelease = 'Debian GNU/Linux 11 (bullseye)',
  upTime = '2 days',
  provider = 'Linux 5.4.0 124-generic',
  architecture = 'x86_64',
  ipAddress = '89.0.142.86',
  modified = '2022-01-01',
}) => {
  return (
    <>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>CPU</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Link to={hostname}>
            <Flex gap={4}>
              <IconExternalLink color={theme.colors?.black?.[10]} />
              <P1MediumUnderline color={theme.colors?.black?.[10]}>{hostname}</P1MediumUnderline>
            </Flex>
          </Link>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>OS Release</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {osRelease}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Uptime</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="dot" color="red" size="lg">
            {upTime}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Provider</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {provider}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Architecture</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {architecture}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>IP address</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input value={ipAddress} variant="outline" size="md"></Input>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Modified</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <P1Medium>{modified}</P1Medium>
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default SystemPanel;
