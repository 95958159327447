import { DateInput } from '@mantine/dates';
import classNames from 'theme/components/Inputs/DateInput/DateInput.module.css';

export default DateInput.extend({
  classNames,
  defaultProps: {
    variant: 'solid',
    size: 'md',
  },
});
