import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import ActionsButtonsRenderer from 'components/ActionsButtonsRenderer';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import { VPS_SERVERS_DETAILS_PATH } from 'routes/paths';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
  },
};

/**
 * A functional component that renders the main Wordpress Servers page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Servers page.
 */
const Servers: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      Id: 2,
      Title: 'Aquacity - shop.aquacity.sk',
      IPAddress: '95.111.233.73',
      OSRelease: 'Ubuntu 20.04.4 LTS',
      Provider: 'CONTABO',
      Owner: 'OTHER',
      Uptime: 252,
      PackageUpgrades: { upgrades: 25, securityUpgrades: 4 },
      Modified: '2023-04-25 23:00:11.649784',
    },
    {
      Id: 2,
      Title: 'FM Point 2.0 - dev',
      IPAddress: '95.111.233.73',
      OSRelease: 'Ubuntu 20.04.4 LTS',
      Provider: 'CONTABO',
      Owner: 'CREANET',
      Uptime: 17,
      PackageUpgrades: { upgrades: 5, securityUpgrades: 0 },
      Modified: '2023-04-25 23:00:11.649784',
    },
    {
      Id: 2,
      Title: 'Internal - gitlab runners (185.192.97.105) + SonarQube',
      IPAddress: '95.111.233.73',
      OSRelease: 'Ubuntu 20.04.4 LTS',
      Provider: 'OTHER',
      Owner: 'OTHER',
      Uptime: 21,
      PackageUpgrades: { securityUpgrades: 1 },
      Modified: '2023-04-25 23:00:11.649784',
    },
    // ... More rows based on your image
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [selectedProvider, setSelectedProvider] = useState<string | null>(null);
  const [selectedOwner, setSelectedOwner] = useState<string | null>(null);

  const uniqueTitles = useMemo(() => {
    const titles = rowData.map((row) => row.Title);
    return [...new Set(titles)];
  }, [rowData]);

  const uniqueProviders = useMemo(() => {
    const providers = rowData.map((row) => row.Provider);
    return [...new Set(providers)];
  }, [rowData]);

  const uniqueOwners = useMemo(() => {
    const owners = rowData.map((row) => row.Owner);
    return [...new Set(owners)];
  }, [rowData]);

  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.Title.toLowerCase().includes(searchValue.toLowerCase());
      const matchesTitle = selectedTitle ? row.Title === selectedTitle : true;
      const matchesProvider = selectedProvider ? row.Provider === selectedProvider : true;
      const matchesOwner = selectedOwner ? row.Owner === selectedOwner : true;

      return matchesSearch && matchesTitle && matchesProvider && matchesOwner;
    });
  }, [searchValue, selectedTitle, selectedProvider, selectedOwner, rowData]);

  const [colDefs] = useState<ColDef[]>([
    {
      field: 'Title',
      headerName: 'Title',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
          </>
        );
      },
    },
    {
      field: 'IPAddress',
      headerName: 'IP Address',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Badge variant="neutral" size="lg">
              {params.value}
            </Badge>
          </>
        );
      },
    },
    {
      field: 'OSRelease',
      headerName: 'OS Release',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Badge variant="neutral" size="lg">
              {params.value}
            </Badge>
          </>
        );
      },
    },
    {
      field: 'Provider',
      headerName: 'Provider',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            {params.value === 'OTHER' ? (
              <Badge variant="gray" size="lg">
                {params.value}
              </Badge>
            ) : (
              <Badge variant="darkblue" size="lg">
                {params.value}
              </Badge>
            )}
          </>
        );
      },
    },
    {
      field: 'Owner',
      headerName: 'Owner',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            {params.value === 'OTHER' ? (
              <Badge variant="gray" size="lg">
                {params.value}
              </Badge>
            ) : (
              <Badge variant="blue" size="lg">
                {params.value}
              </Badge>
            )}
          </>
        );
      },
    },
    {
      field: 'Uptime',
      headerName: 'Uptime',

      cellRenderer: (params: { value: any }) => {
        const days = params.value;
        let color = '#10A84D';
        if (days > 200) {
          color = '#EE434E';
        } else if (days > 20) {
          color = '#CDBB1C';
        }
        return (
          <>
            <Badge variant="dot" size="xl" color={color}>
              {params.value} days
            </Badge>
          </>
        );
      },
    },
    {
      field: 'PackageUpgrades',
      headerName: 'Package upgrades',
      cellRenderer: (params: { value: any }) => {
        const { upgrades = 0, securityUpgrades = 0 } = params.value || {};
        /**
         * Returns a color based on the number of package upgrades.
         * Gray for 0 upgrades, green for up to 10 upgrades, yellow for up to 25 upgrades and red for more than 25 upgrades.
         * @param count {number} The number of package upgrades.
         * @returns {string} A color string.
         */
        const getBadgeColor = (count: number) => {
          if (count === 0) return 'gray';
          if (count > 0 && count <= 10) return 'green';
          if (count > 10 && count <= 20) return 'yellow';
          return 'red';
        };

        return (
          <Flex direction="column" align="start" gap={4}>
            {upgrades === 0 && securityUpgrades === 0 ? (
              <Badge variant="gray" size="lg">
                NO UPGRADES
              </Badge>
            ) : (
              <>
                {upgrades > 0 && (
                  <Badge variant={getBadgeColor(upgrades)} size="lg">
                    UPGRADES: {upgrades}
                  </Badge>
                )}
                {securityUpgrades > 0 && (
                  <Badge variant="yellow" size="lg">
                    SECURITY UPGRADES: {securityUpgrades}
                  </Badge>
                )}
              </>
            )}
          </Flex>
        );
      },
    },
    {
      field: 'Modified',
      headerName: 'Modified',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
          </>
        );
      },
    },
    {
      headerName: '',
      field: 'Action',
      width: 150,
      cellRenderer: (params: any) => {
        return <ActionsButtonsRenderer params={params} customPath={VPS_SERVERS_DETAILS_PATH.original} />;
      },
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of servers</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>48 servers</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />

        <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
          <Select
            label="Title"
            placeholder="Pick value"
            data={uniqueTitles}
            value={selectedTitle}
            onChange={setSelectedTitle}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="Provider"
            placeholder="Pick value"
            data={uniqueProviders}
            value={selectedProvider}
            onChange={setSelectedProvider}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="Owner"
            placeholder="Pick value"
            data={uniqueOwners}
            value={selectedOwner}
            onChange={setSelectedOwner}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Servers;
