import { Grid, Flex, Badge, TextInput, Select, Divider, Button } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';

/**
 * A functional component that displays a server panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} props.id - The unique identifier.
 * @param {string} props.title - The title of the server panel.
 * @param {string} props.created - The creation timestamp.
 * @param {string} props.modified - The modification timestamp.
 * @param {string} props.provider - The provider of the server.
 * @param {string} props.owner - The owner of the server.
 * @param {string[]} props.tags - The tags of the server.
 * @return {JSX.Element} The server panel component.
 */
const ServerPanel = ({
  id = 1,
  title = '500 km slovenských',
  created = '2022-01-01',
  modified = '2022-01-01',
  provider = 'Contabo',
  owner = 'Creanet',
  tags = ['PHP - JSON', 'PHP - XML', 'PHP - GD'],
}) => {
  return (
    <>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>UUID</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <P1Medium>{id}</P1Medium>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Title</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <TextInput value={title} variant="outline" size="md" />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }}>
          <P1Semibold>Tags</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Flex gap={8}>
            {tags.map((tag, index) => (
              <Badge key={index} variant="gray" size="lg">
                {tag}
              </Badge>
            ))}
          </Flex>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Provider</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Select value={provider} variant="outline" data={['Contabo', 'Provider 1', 'Provider 2']} />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Owner</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Select value={owner} variant="outline" data={['Creanet', 'Owner 1', 'Owner 2']} />
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Created</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <P1Medium>{created}</P1Medium>
        </Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Modified</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <P1Medium>{modified}</P1Medium>
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default ServerPanel;
