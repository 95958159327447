import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Box, Button, Checkbox, Divider, Flex, Input, Modal, Pill } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconExternalLink, IconPlus } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import H5Semibold from 'theme/components/Typography/H5Semibold';
import P2Medium from 'theme/components/Typography/P2Medium';
import { useDisclosure } from '@mantine/hooks';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
  },
};

/**
 * A functional component that renders the main Wordpress Compare page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Compare page.
 */
const Compare: React.FC = () => {
  const [openedProjects, { open: openProjects, close: closeProjects }] = useDisclosure(false);
  const [openedPlugins, { open: openPlugins, close: closePlugins }] = useDisclosure(false);
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedPlugins, setSelectedPlugins] = useState<string[]>([]);
  const [searchTermPlugin, setSearchTermPlugin] = useState<string>('');
  const [showTable, setShowTable] = useState<boolean>(false); // New state to control table visibility

  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      project: '500kmsk',
      plugins: [
        { name: 'WooCommerce', version: '2.6.0' },
        { name: 'WooCommerceMarketing', version: '2.5.0' },
      ],
    },
    {
      project: 'Amis energie',
      plugins: [
        { name: 'WooCommerce', version: '2.6.0' },
        { name: 'WooCommerceMarketing', version: '2.5.0' },
        { name: 'ACF', version: '5.6.1' },
        { name: 'WPML', version: '2.3.1' },
      ],
    },
  ]);

  const availableProjects = rowData.map((row) => row.project);
  const availablePlugins = rowData.map((row) => row.plugins.map((plugin) => plugin.name));

  const plugins = [...new Set(availablePlugins.flat())];

  // Filtered projects based on search term
  const filteredProjects = availableProjects.filter((project) =>
    project.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Filtered projects based on search term
  const filteredPlugins = plugins.filter((plugin) => plugin.toLowerCase().includes(searchTermPlugin.toLowerCase()));

  /**
   * Handles the toggle of a selected project. If the project is already selected,
   * it will be removed from the selected projects. If the project is not selected,
   * it will be added to the selected projects.
   *
   * @param {string} project The name of the project to be toggled.
   * @return {void} No return value.
   */
  const handleToggle = (project: string) => {
    setSelectedProjects((prevSelected) =>
      prevSelected.includes(project) ? prevSelected.filter((item) => item !== project) : [...prevSelected, project]
    );
  };

  /**
   * Handles the toggle of a selected plugin. If the plugin is already selected,
   * it will be removed from the selected plugins. If the plugin is not selected,
   * it will be added to the selected plugins.
   *
   * @param {string} plugin The name of the plugin to be toggled.
   * @return {void} No return value.
   */
  const handleTogglePlugin = (plugin: string) => {
    setSelectedPlugins((prevSelected) =>
      prevSelected.includes(plugin) ? prevSelected.filter((item) => item !== plugin) : [...prevSelected, plugin]
    );
  };

  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  const filteredData = useMemo(() => {
    return selectedPlugins.map((plugin) => {
      const pluginData: { [key: string]: string | undefined } = { pluginName: plugin };
      selectedProjects.forEach((project) => {
        const projectData = rowData.find((row) => row.project === project);
        if (projectData) {
          const pluginInfo = projectData.plugins.find((p) => p.name === plugin);
          pluginData[project] = pluginInfo ? pluginInfo.version : '--'; // Replace empty data with '--'
        }
      });
      return pluginData;
    });
  }, [selectedProjects, selectedPlugins, rowData]);

  const dynamicColDefs = useMemo(() => {
    return selectedProjects.map((project) => ({
      field: project,
      headerName: project,
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={72} gap={4}>
          <Badge variant="neutral" size="lg">
            {params.value}
          </Badge>
        </Flex>
      ),
    }));
  }, [selectedProjects]);

  const colDefs = useMemo<ColDef[]>(() => {
    return [
      {
        field: 'pluginName',
        headerName: 'Plugin Name',
        cellRenderer: (params: { value: any }) => (
          <Flex align="center" h={72} gap={4}>
            <IconExternalLink color={theme.colors?.black?.[8]} />
            <P1MediumUnderline color={theme.colors?.black?.[10]}>{params.value}</P1MediumUnderline>
          </Flex>
        ),
      },
      ...dynamicColDefs,
    ];
  }, [dynamicColDefs]);

  /**
   * Handles the Compare button click event.
   *
   * Sets the `showTable` state to `true`, showing the table when the Compare button is clicked.
   *
   * @return {void} No return value.
   */
  const handleCompare = () => {
    setShowTable(true); // Show the table when Compare is clicked
  };

  /**
   * Resets the selected projects and plugins, and hides the table when the Reset button is clicked.
   *
   * @return {void} No return value.
   */
  const handleReset = () => {
    setSelectedProjects([]);
    setSelectedPlugins([]);
    setShowTable(false); // Hide the table when Reset is clicked
  };

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>Wordpress Plugin Comparator</H2Semibold>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} py={16} gap={16}>
        <Box
          bg={theme.colors?.black?.[1]}
          py={16}
          px={24}
          style={{ borderRadius: '8px' }}
          w={{ base: '100%', sm: '50%' }}
        >
          <H5Semibold>Selected Wordpress projects</H5Semibold>
          <P2Medium pb={16}>Môžte vybrať plugin ktorý chcete porovnať</P2Medium>
          {/* Display selected projects */}
          {selectedProjects.length > 0 && (
            <Flex mb={16} gap={8} wrap="wrap">
              {selectedProjects.map((project) => (
                <Pill key={project} size="lg" withRemoveButton onRemove={() => handleToggle(project)}>
                  {project}
                </Pill>
              ))}
            </Flex>
          )}
          <Button variant="subtle" size="sm" leftSection={<IconPlus />} onClick={openProjects}>
            Choose projects
          </Button>
        </Box>
        <Box
          bg={theme.colors?.black?.[1]}
          py={16}
          px={24}
          style={{ borderRadius: '8px' }}
          w={{ base: '100%', sm: '50%' }}
        >
          <H5Semibold>Selected Plugins</H5Semibold>
          <P2Medium pb={16}>Môžte vybrať plugin ktorý chcete porovnať</P2Medium>
          {/* Display selected projects */}
          {selectedPlugins.length > 0 && (
            <Flex mb={16} gap={8} wrap="wrap">
              {selectedPlugins.map((plugin) => (
                <Pill key={plugin} size="lg" withRemoveButton onRemove={() => handleTogglePlugin(plugin)}>
                  {plugin}
                </Pill>
              ))}
            </Flex>
          )}
          <Button variant="subtle" size="sm" leftSection={<IconPlus />} onClick={openPlugins}>
            Choose plugins
          </Button>
        </Box>
      </Flex>
      <Flex gap={10} pb={16}>
        <Button
          variant="primary"
          size="sm"
          onClick={handleCompare}
          disabled={selectedProjects.length === 0 || selectedPlugins.length === 0}
        >
          Compare
        </Button>
        <Button variant="outline" size="sm" onClick={handleReset} disabled={!showTable}>
          Reset
        </Button>
      </Flex>

      <Modal opened={openedProjects} onClose={closeProjects} title="Choose projects" size="lg">
        <Divider mt={8} pb={8} />
        <Input
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.currentTarget.value)}
          my={16}
          variant="outline"
        />
        {filteredProjects.map((project) => (
          <Checkbox
            key={project}
            label={project}
            checked={selectedProjects.includes(project)}
            onChange={() => handleToggle(project)}
          />
        ))}
        <Flex justify="space-between">
          <Button onClick={closeProjects} mt={16} variant="tertiary" size="sm">
            Cancel
          </Button>
          <Button onClick={closeProjects} mt={16} variant="primary" size="sm">
            Compare
          </Button>
        </Flex>
      </Modal>
      <Modal opened={openedPlugins} onClose={closePlugins} title="Choose plugins" size="lg">
        <Divider mt={8} pb={8} />
        <Input
          placeholder="Search plugins..."
          value={searchTermPlugin}
          onChange={(event) => setSearchTermPlugin(event.currentTarget.value)}
          my={16}
          variant="outline"
        />
        {filteredPlugins.map((plugin) => (
          <Checkbox
            key={plugin}
            label={plugin}
            checked={selectedPlugins.includes(plugin)}
            onChange={() => handleTogglePlugin(plugin)}
          />
        ))}
        <Flex justify="space-between">
          <Button onClick={closePlugins} mt={16} variant="tertiary" size="sm">
            Cancel
          </Button>
          <Button onClick={closePlugins} mt={16} variant="primary" size="sm">
            Compare
          </Button>
        </Flex>
      </Modal>
      {showTable && (
        <div className="ag-theme-quartz ag-theme-acmecorp">
          <AgGridReact
            ref={gridRef}
            rowData={filteredData}
            columnDefs={colDefs}
            rowHeight={72}
            pagination={true}
            gridOptions={gridOptions}
            paginationPageSize={pageSize}
            domLayout="autoHeight"
            onPaginationChanged={() => {
              if (gridRef.current && gridRef.current.api) {
                const api = gridRef.current.api;
                setCurrentPage(api.paginationGetCurrentPage());
                setTotalPages(api.paginationGetTotalPages());
                setRowCount(api.paginationGetRowCount());
              }
            }}
          />

          <Flex
            justify="space-between"
            align="center"
            mt={16}
            style={{ borderTop: '1px solid #E1E2EA' }}
            pt={{ base: 16, sm: 0 }}
            direction={{ base: 'column', sm: 'row' }}
          >
            <Flex>
              <P1Medium color={theme.colors?.black?.[10]}>
                Záznamy{' '}
                <b>
                  {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
                </b>{' '}
                Celkom <b>{rowCount}</b>
              </P1Medium>
            </Flex>
            <Flex gap="sm" align="center" py="md">
              <IconChevronLeft
                onClick={() => goToPage(Math.max(currentPage - 1, 0))}
                style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
                color={theme.colors?.black?.[10]}
              />
              {getDisplayedPages().map((page) => (
                <Button
                  key={page}
                  size="sm"
                  variant="tertiary"
                  onClick={() => goToPage(page)}
                  style={{
                    backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                  }}
                >
                  {page + 1}
                </Button>
              ))}
              <IconChevronRight
                onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
                style={{
                  cursor: 'pointer',
                  opacity: currentPage === totalPages - 1 ? 0.5 : 1,
                }}
                color={theme.colors?.black?.[10]}
              />
            </Flex>
          </Flex>
        </div>
      )}
    </>
  );
};

export default Compare;
