import React from 'react';
import { Line } from 'react-chartjs-2';
import { ActionIcon, Box, Flex } from '@mantine/core';
import {
  IconArrowsMove,
  IconDownload,
  IconExternalLink,
  IconRotateClockwise,
  IconScreenshot,
} from '@tabler/icons-react';
import { AgGridReact } from 'ag-grid-react';
import { ColDef } from 'ag-grid-community';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import theme from 'theme';
import { Link } from 'react-router-dom';

// ChartJS and DataGrid imports are already in your code above

const data = {
  labels: [
    '1.1.2024',
    '2.1.2024',
    '3.1.2024',
    '4.1.2024',
    '5.1.2024',
    '6.1.2024',
    '7.1.2024',
    '8.1.2024',
    '9.1.2024',
    '10.1.2024',
    '11.1.2024',
    '12.1.2024',
    '13.1.2024',
    '14.1.2024',
    '15.1.2024',
    '16.1.2024',
  ],
  datasets: [
    {
      label: 'Sample Data',
      data: [1, 3, 2, 2, 5, 4, 4, 4, 3, 2, 1, 0, 0, 0, 0, 0],
      fill: 'start',
      backgroundColor: 'rgba(255, 99, 132, 0.2)',
      borderColor: 'rgba(255, 99, 132, 1)',
      tension: 0,
    },
  ],
};

const options = {
  scales: {
    y: {
      beginAtZero: true,
      max: 6,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

/**
 * A React functional component that renders an errors panel with dynamic data.
 *
 * @return {JSX.Element} The JSX element representing the errors panel.
 */
const ErrorsPanel: React.FC = () => {
  const [dynamicData] = React.useState([
    {
      type: 'Warning',
      count: 1,
      message:
        'rename(/data/5/e/5e0e3fcb-26ee-494c-af01-5ed99cb74b45/500km.sk/web/wp-content/debug.log,/data/5/e/5e0e3fcb-26ee-494c-af01-5ed99cb ... /data/5/e/5e0e3fcb-26ee-494c-af01-5ed99cb74b45/500km.sk/web/wp-content/plugins/creanet-wp-status/Classes/Logs.php:37',
      fileLink: 'https://500km.sk/wp-content/logs/debug-20230905_2150.log.gz',
    },
    {
      type: 'Warning',
      count: 2,
      message: 'filesize(): stat failed for /data/5/e/5e0e3fcb-26ee-494c-af01...',
      fileLink: 'https://500km.sk/wp-content/logs/debug-20230904_2150.log.gz',
    },
  ]);

  const [colDefs] = React.useState<ColDef[]>([
    {
      field: 'type',
      headerName: 'Type',
      width: 150,
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={128}>
          <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
        </Flex>
      ),
    },
    {
      field: 'count',
      headerName: 'Count',
      width: 100,
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={128} justify="center">
          <P1Semibold>{params.value}</P1Semibold>
        </Flex>
      ),
    },
    {
      field: 'message',
      headerName: 'Message',
      width: 750,
      cellStyle: { 'white-space': 'normal' },
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={128}>
          <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
        </Flex>
      ),
    },
    {
      field: 'fileLink',
      headerName: 'File link',
      width: 500,
      cellStyle: { 'white-space': 'normal' },
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={128} gap={4}>
          <IconExternalLink />
          <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
        </Flex>
      ),
    },
    {
      field: 'link',
      headerName: '',
      width: 150,
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={128}>
          <Link to="">
            <Flex align="center" gap={4}>
              <IconDownload color="#1D40F7" />
              <P1Semibold color="#1D40F7">Download</P1Semibold>
            </Flex>
          </Link>
        </Flex>
      ),
    },
  ]);

  return (
    <>
      <Flex gap={8} py={24}>
        <ActionIcon variant="tertiary" size="lg">
          <IconScreenshot />
        </ActionIcon>
        <ActionIcon variant="tertiary" size="lg">
          <IconArrowsMove />
        </ActionIcon>
        <ActionIcon variant="tertiary" size="lg">
          <IconRotateClockwise />
        </ActionIcon>
      </Flex>
      <div style={{ height: '300px' }}>
        <Line data={data} options={options} width="100%" />
      </div>
      <Box py={24}>
        <div className="ag-theme-quartz ag-theme-acmecorp">
          <AgGridReact rowData={dynamicData} columnDefs={colDefs} rowHeight={128} domLayout="autoHeight" />
        </div>
      </Box>
    </>
  );
};

export default ErrorsPanel;
