import { SweetPath } from 'sweet-path';

// Login
export const LOGIN_PAGE_CLASSIC_PATH = new SweetPath('/login');
export const LOGIN_PAGE_GITLAB_PATH = new SweetPath('/login/gitlab');

// Dashboard
export const DASHBOARD_PATH = new SweetPath('/dashboard');

// Wordpress
export const WP_PROJECTS_PATH = new SweetPath('/wordpress/projects');
export const WP_PROJECTS_DETAILS_PATH = new SweetPath('/wordpress/projects/detail/:id');
export const WP_PROJECTS_EDIT_PATH = new SweetPath('/wordpress/projects/edit/:id');

export const WP_VERSIONS_PATH = new SweetPath('/wordpress/versions');

export const WP_PLUGINS_PATH = new SweetPath('/wordpress/plugins');
export const WP_PLUGINS_DETAILS_PATH = new SweetPath('/wordpress/plugins/:id');

export const WP_THEMES_PATH = new SweetPath('/wordpress/themes');
export const WP_USERS_PATH = new SweetPath('/wordpress/users');
export const WP_PROBLEMS_PATH = new SweetPath('/wordpress/problems');
export const WP_LOGS_PATH = new SweetPath('/wordpress/log');
export const WP_CHANGELOG_PATH = new SweetPath('/wordpress/plugin-changelog');
export const WP_COMPARE_PATH = new SweetPath('/wordpress/plugin-compare');

// VPS
export const VPS_SERVERS_PATH = new SweetPath('/vps/servers');
export const VPS_SERVERS_DETAILS_PATH = new SweetPath('/vps/servers/:id');
export const VPS_SSHKEY_PATH = new SweetPath('/vps/sshkey');
export const VPS_SSHKEY_DETAILS_PATH = new SweetPath('/vps/sshkey/detail/:id');
export const VPS_PROBLEMS_PATH = new SweetPath('/vps/problems');

// Backups

export const BACKUPS_SERVERS_PATH = new SweetPath('/backups/servers');
export const BACKUPS_DATABASES_PATH = new SweetPath('/backups/databases');
