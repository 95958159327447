import React from 'react';

/**
 * A React functional component that renders an alert icon.
 *
 * @return {JSX.Element} The JSX element representing the alert icon.
 */
const AlertIcon = () => {
  return (
    <div
      style={{
        boxShadow: '0px 3px 8px 0px rgba(238, 67, 78, 0.25)',
        width: '24px',
        height: '24px',
        backgroundColor: '#EE434E',
        borderRadius: '50%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
      }}
    >
      !
    </div>
  );
};

export default AlertIcon;
