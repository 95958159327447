import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { Badge, Flex } from '@mantine/core';
import { ColDef } from 'ag-grid-community';

// Define a TypeScript interface for your row data
interface RowData {
  name: string;
  value: string;
}

/**
 * A functional component that renders a grid panel for displaying WordPress Constants.
 *
 * @return {JSX.Element} The JSX element representing the Constants panel.
 */
const ConstantsPanel: React.FC = () => {
  // Define column definitions with the correct type
  const colDefs: ColDef<RowData>[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: true,
      width: 500,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },

    {
      headerName: 'Value',
      field: 'value',
      sortable: true,
      filter: true,
      width: 500,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          <Badge variant="neutral" size="lg">
            {params.value}
          </Badge>
        </Flex>
      ),
    },
  ];

  // Define row data with the correct type
  const rowData: RowData[] = [
    {
      name: 'WP_DEBUG',
      value: '1',
    },
    {
      name: 'WP_DEBUG',
      value: '1',
    },
    {
      name: 'WP_DEBUG',
      value: '1',
    },
  ];

  return (
    <>
      <div className="ag-theme-quartz ag-theme-acmecorp" style={{ width: '100%', paddingTop: '16px' }}>
        <AgGridReact columnDefs={colDefs} rowData={rowData} rowHeight={56} domLayout="autoHeight" />
      </div>
    </>
  );
};

export default ConstantsPanel;
