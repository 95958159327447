import React from 'react';
import { BackgroundImage, Box, Button, Flex } from '@mantine/core';
import { IconBrandGitlab } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import H1Medium from 'theme/components/Typography/H1Medium';
import theme from 'theme';
import SimpleLayout from 'layouts/SimpleLayout';
import { LOGIN_PAGE_CLASSIC_PATH } from 'routes/paths';

/**
 * Sign in page
 * @param param0
 * @returns
 */
const signInGitlab: React.FC = () => (
  <>
    <SimpleLayout>
      <BackgroundImage src="../img/ratio-spacer.png">
        <Box style={{ background: '#17182180' }} h={223}>
          <H1Medium color={theme.white} p={16}>
            Website Control From One Place.
          </H1Medium>
        </Box>
      </BackgroundImage>
      <Flex direction="column">
        <Button size="md" variant="primary" leftSection={<IconBrandGitlab size={24} />}>
          Sign in with Gitlab
        </Button>
        <Link
          to={LOGIN_PAGE_CLASSIC_PATH.original}
          style={{ color: theme.white, textAlign: 'center', paddingTop: '16px' }}
        >
          Sign in form
        </Link>
      </Flex>
    </SimpleLayout>
  </>
);

export default signInGitlab;
