import { rem } from '@mantine/core';

// See https://www.figma.com/file/M2RU8Nr32l3lDgCCM3PjVL/FM-Point?type=design&node-id=73-554&mode=dev

export const H1Medium = {
  fontSize: rem(32),
  fontWeight: 600,
  lineHeight: rem(40),
};

export const H2Medium = {
  fontSize: rem(28),
  fontWeight: 500,
  lineHeight: rem(36),
};

export const H2Semibold = {
  fontSize: rem(28),
  fontWeight: 600,
  lineHeight: rem(36),
};

export const H3Medium = {
  fontSize: rem(22),
  fontWeight: 500,
  lineHeight: rem(32),
};

export const H3Semibold = {
  fontSize: rem(22),
  fontWeight: 600,
  lineHeight: rem(32),
};

export const H4Semibold = {
  fontSize: rem(20),
  fontWeight: 600,
  lineHeight: rem(28),
};

export const H5Semibold = {
  fontSize: rem(18),
  fontWeight: 600,
  lineHeight: rem(24),
};

export const P1Semibold = {
  fontSize: rem(16),
  fontWeight: 600,
  lineHeight: rem(24),
};

export const P1Medium = {
  fontSize: rem(16),
  fontWeight: 500,
  lineHeight: rem(24),
};

export const P1SemiboldUnderline = {
  fontSize: rem(16),
  fontWeight: 600,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P1MediumUnderline = {
  fontSize: rem(16),
  fontWeight: 500,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P1Regular = {
  fontSize: rem(16),
  fontWeight: 400,
  lineHeight: rem(24),
};

export const P2Semibold = {
  fontSize: rem(14),
  fontWeight: 600,
  lineHeight: rem(24),
};

export const P2Medium = {
  fontSize: rem(14),
  fontWeight: 500,
  lineHeight: rem(24),
};

export const P2MediumUnderline = {
  fontSize: rem(14),
  fontWeight: 500,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P2SemiboldUnderline = {
  fontSize: rem(14),
  fontWeight: 600,
  lineHeight: rem(24),
  textDecoration: 'underline',
};

export const P2Regular = {
  fontSize: rem(14),
  fontWeight: 400,
  lineHeight: rem(24),
};

export const P3Semibold = {
  fontSize: rem(13),
  fontWeight: 600,
  lineHeight: rem(20),
};

export const P4Medium = {
  fontSize: rem(12),
  fontWeight: 500,
  lineHeight: rem(16),
};

export const P5Semibold = {
  fontSize: rem(10),
  fontWeight: 600,
  lineHeight: rem(16),
};

export const P6Semibold = {
  fontSize: rem(8),
  fontWeight: 600,
  lineHeight: rem(16),
};
