import { Flex, Button, Tabs } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconAmpersand,
  IconBrush,
  IconChevronLeft,
  IconInfoCircle,
  IconMessageCircle,
  IconPencil,
  IconPuzzle,
  IconRotateClockwise,
  IconUsers,
} from '@tabler/icons-react';
import React from 'react';
//import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import P2Semibold from 'theme/components/Typography/P2Semibold';
import P3Semibold from 'theme/components/Typography/P3Semibold';

import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import ErrorsPanel from 'pages/wordpress/wordpressProjects/ErrorPanel';
import InfoPanel from 'pages/wordpress/wordpressProjects/InfoPanel';
import ThemesPanel from 'pages/wordpress/wordpressProjects/ThemesPanel';
import ConstantsPanel from 'pages/wordpress/wordpressProjects/ConstantsPanel';
import PluginsPanel from 'pages/wordpress/wordpressProjects/PluginsPanel';
import UsersPanel from 'pages/wordpress/wordpressProjects/UsersPanel';
import { WP_PROJECTS_EDIT_PATH } from 'routes/paths';
import { useNavigate } from 'react-router-dom';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

/**
 * A functional component that displays the details of a project based on its ID.
 *
 * @return {JSX.Element} The JSX element representing the project details page.
 */
const ProjectDetails: React.FC = () => {
  //const { id } = useParams<{ id: string }>(); // Access the ID from the URL
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  return (
    <>
      <Flex justify="space-between" pb={16} gap={16} direction={{ base: 'column', md: 'row' }}>
        <Flex align="center" gap={8}>
          <IconChevronLeft
            size={30}
            color={theme.colors?.black?.[10]}
            strokeWidth={1.5}
            onClick={() => window.history.back()}
          />
          <H2Semibold color={theme.colors?.black?.[10]}>500 km slovenských</H2Semibold>
        </Flex>
        <Flex gap={8}>
          <Flex align="center" gap={4} pr={{ base: 0, md: 16 }}>
            <P2Semibold color={theme.colors?.black?.[8]}>Problems</P2Semibold>
            <div
              style={{
                boxShadow: '0px 3px 8px 0px rgba(238, 67, 78, 0.25)',
                width: '24px',
                height: '24px',
                backgroundColor: '#EE434E',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <P3Semibold color={theme.white}>3</P3Semibold>
            </div>
          </Flex>
          <Button
            variant="tertiary"
            size={isSmallScreen ? 'xs' : 'sm'}
            leftSection={<IconPencil />}
            onClick={() => navigate(WP_PROJECTS_EDIT_PATH.original.replace(':id', '1'))}
          >
            Edit project
          </Button>
          <Button variant="tertiary" size={isSmallScreen ? 'xs' : 'sm'} leftSection={<IconRotateClockwise />}>
            Refresh
          </Button>
        </Flex>
      </Flex>
      <Tabs color={theme.colors?.black?.[10]} defaultValue="info">
        <Tabs.List>
          <Tabs.Tab value="info" leftSection={<IconInfoCircle />}>
            Wordpress info
          </Tabs.Tab>
          <Tabs.Tab value="errors" leftSection={<IconMessageCircle />}>
            Errors
          </Tabs.Tab>
          <Tabs.Tab value="themes" leftSection={<IconBrush />}>
            Themes
          </Tabs.Tab>
          <Tabs.Tab value="constants" leftSection={<IconAmpersand />}>
            Constants
          </Tabs.Tab>
          <Tabs.Tab value="plugins" leftSection={<IconPuzzle />}>
            Plugins
          </Tabs.Tab>
          <Tabs.Tab value="users" leftSection={<IconUsers />}>
            Users
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="info">
          <InfoPanel />
        </Tabs.Panel>

        <Tabs.Panel value="errors">
          <ErrorsPanel />
        </Tabs.Panel>

        <Tabs.Panel value="themes">
          <ThemesPanel />
        </Tabs.Panel>

        <Tabs.Panel value="constants">
          <ConstantsPanel />
        </Tabs.Panel>

        <Tabs.Panel value="plugins">
          <PluginsPanel />
        </Tabs.Panel>

        <Tabs.Panel value="users">
          <UsersPanel />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default ProjectDetails;
