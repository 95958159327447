import React from 'react';
import { Grid, Flex, Badge } from '@mantine/core';
import { IconExternalLink } from '@tabler/icons-react';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import P1Medium from 'theme/components/Typography/P1Medium';
import theme from 'theme';
import { Link } from 'react-router-dom';

/**
 * A functional component that displays an information panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} props.id - The unique identifier.
 * @param {string} props.title - The title of the information panel.
 * @param {string} props.customer - The customer name.
 * @param {string} props.hostUrl - The host URL.
 * @param {string} props.adminUrl - The admin URL.
 * @param {string} props.contact - The contact email.
 * @param {string} props.wordpressVersion - The WordPress version.
 * @param {string} props.phpVersion - The PHP version.
 * @param {string} props.database - The database type.
 * @param {string} props.technicalDebt - The technical debt details.
 * @param {Object} props.development - The development details.
 * @param {number} props.development.major - The major version.
 * @param {number} props.development.minor - The minor version.
 * @param {number} props.development.patch - The patch version.
 * @param {number} props.development.upToDate - The up-to-date version.
 * @param {number} props.development.unknown - The unknown version.
 * @param {string} props.lastUpdate - The last update timestamp.
 * @param {string} props.problems - The problems or issues.
 * @return {JSX.Element} The information panel component.
 */
const InfoPanel = ({
  id = 1,
  title = '500 km slovenských',
  customer = '500km slovenských, o.z.',
  hostUrl = 'https://500km.sk',
  adminUrl = 'https://500km.sk/admin',
  contact = 'obysovskyjan@gmail.com',
  wordpressVersion = 'w6.0.2',
  phpVersion = '5.6.9',
  database = 'MySQL',
  technicalDebt = '0h 55min.',
  development = {
    major: 1,
    minor: 1,
    patch: 1,
    upToDate: 1,
    unknown: 1,
  },
  lastUpdate = '2023-11-01 22:41:23',
  problems = 'Outdated Creanet Status plugin',
}) => {
  return (
    <Grid pt={16} w={{ base: '100%', md: '70%' }}>
      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>UUID</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <P1Medium>{id}</P1Medium>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Title</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <P1Medium>{title}</P1Medium>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }}>
        <P1Semibold>Customer</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <P1Medium>{customer}</P1Medium>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Host</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Link to={hostUrl} color={theme.colors?.black?.[10]}>
          <Flex align="center">
            <IconExternalLink size={24} color={theme.colors?.black?.[8]} />
            <P1MediumUnderline color={theme.colors?.black?.[10]}>{hostUrl}</P1MediumUnderline>
          </Flex>
        </Link>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Admin URL</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Link to={adminUrl} color={theme.colors?.black?.[10]}>
          <Flex align="center">
            <IconExternalLink size={24} color={theme.colors?.black?.[8]} />
            <P1MediumUnderline color={theme.colors?.black?.[10]}>{adminUrl}</P1MediumUnderline>
          </Flex>
        </Link>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Contact</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Link to={`mailto:${contact}`}>
          <P1Medium color={theme.colors?.black?.[10]}>{contact}</P1Medium>
        </Link>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Wordpress</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Badge variant="neutral">{wordpressVersion}</Badge>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>PHP</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Badge variant="neutral">{phpVersion}</Badge>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Database</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Badge variant="neutral">{database}</Badge>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Technical debt.</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Badge variant="dot" color="green">
          {technicalDebt}
        </Badge>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Development</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <Flex gap={12} wrap="wrap">
          <Badge variant="red">MAJOR: {development.major}</Badge>
          <Badge variant="yellow">MINOR: {development.minor}</Badge>
          <Badge variant="blue">PATCH: {development.patch}</Badge>
          <Badge variant="green">UP TO DATE: {development.upToDate}</Badge>
          <Badge variant="gray">UNKNOWN: {development.unknown}</Badge>
        </Flex>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Last Update</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }} py={16}>
        <P1Medium>{lastUpdate}</P1Medium>
      </Grid.Col>

      <Grid.Col span={{ base: 12, md: 5 }} py={16}>
        <P1Semibold>Problems</P1Semibold>
      </Grid.Col>
      <Grid.Col span={{ base: 12, md: 7 }}>
        <Flex align="center" gap={10} py={16}>
          <div
            style={{
              boxShadow: '0px 3px 8px 0px rgba(238, 67, 78, 0.25)',
              width: '24px',
              height: '24px',
              backgroundColor: '#EE434E',
              borderRadius: '50%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              color: 'white',
            }}
          >
            !
          </div>
          <Link to="">
            <P1MediumUnderline color={theme.colors?.black?.[10]}>{problems}</P1MediumUnderline>
          </Link>
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default InfoPanel;
