import React from 'react';
import { Breadcrumbs, Anchor } from '@mantine/core';
import { useLocation, Link } from 'react-router-dom';
import {
  VPS_SERVERS_DETAILS_PATH,
  VPS_SSHKEY_DETAILS_PATH,
  WP_PLUGINS_DETAILS_PATH,
  WP_PROJECTS_DETAILS_PATH,
  WP_PROJECTS_EDIT_PATH,
} from 'routes/paths';
import P2Medium from 'theme/components/Typography/P2Medium';
import P2MediumUnderline from 'theme/components/Typography/P2MediumUnderline';
import theme from 'theme';

const breadcrumbMap: { [key: string]: string } = {
  '/': 'Home',
  '/dashboard': 'Dashboard',
  '/wordpress': 'Wordpress',
  '/wordpress/projects': 'Wordpress Projects',
  '/wordpress/versions': 'Versions',
  '/wordpress/plugins': 'Plugins',
  '/wordpress/themes': 'Themes',
  '/wordpress/users': 'Users',
  '/wordpress/problems': 'Problems',
  '/wordpress/log': 'Wordpress Log',
  '/wordpress/plugin-changelog': 'Plugin Changelog',
  '/wordpress/plugin-compare': 'Plugin Compare',
  '/vps': 'VPS',
  '/vps/servers': 'Servers',
  '/vps/sshkey': 'List of ssh keys',
  '/vps/problems': 'Problems',
  '/backups': 'Backups',
  '/backups/servers': 'Servers',
  '/backups/databases': 'Databases',
};

/**
 * Renders dynamic breadcrumbs based on the current location.
 *
 * @return {React.ReactElement} The rendered breadcrumbs component.
 */
const DynamicBreadcrumbs: React.FC = () => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbs = pathnames.map((_, index) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
    let breadcrumbName = breadcrumbMap[routeTo];

    // Skip rendering the '/wordpress/projects/...' breadcrumb
    if (
      routeTo === '/wordpress/projects/edit' ||
      routeTo === '/wordpress/projects/detail' ||
      routeTo === '/vps/sshkey/detail'
    ) {
      return null;
    }

    // Check if the current route is the detail page
    if (routeTo.includes(WP_PROJECTS_DETAILS_PATH.original.replace(':id', ''))) {
      const id = pathnames[pathnames.length - 1].replaceAll('%20', ' '); // Get the last part of the URL (which should be the ID)
      breadcrumbName = `${id} • Detail`;
    }

    // Check if the current route is the edit page
    if (routeTo.includes(WP_PROJECTS_EDIT_PATH.original.replace(':id', ''))) {
      const id = pathnames[pathnames.length - 1].replaceAll('%20', ' '); // Get the last part of the URL (which should be the ID)
      breadcrumbName = `${id} • Edit`;
    }

    // Check if the current route is the VPS detail page
    if (routeTo.includes(VPS_SERVERS_DETAILS_PATH.original.replace(':id', ''))) {
      const id = pathnames[pathnames.length - 1].replaceAll('%20', ' '); // Get the last part of the URL (which should be the ID)
      breadcrumbName = `${id} • Detail`;
    }

    // Check if the current route is the VPS detail page
    if (routeTo.includes(VPS_SSHKEY_DETAILS_PATH.original.replace(':id', ''))) {
      const id = pathnames[pathnames.length - 1].replaceAll('%20', ' '); // Get the last part of the URL (which should be the ID)
      breadcrumbName = `${id} • Detail`;
    }

    // Check if the current route is the plugin detail page
    if (routeTo.includes(WP_PLUGINS_DETAILS_PATH.original.replace(':id', ''))) {
      const id = pathnames[pathnames.length - 1].replaceAll('%20', ' '); // Get the last part of the URL (which should be the ID)
      breadcrumbName = `${id} • Detail`;
    }

    const isLastBreadcrumb = index === pathnames.length - 1;

    return isLastBreadcrumb ? (
      <Anchor key={routeTo} component={Link} to={routeTo}>
        <P2Medium color={theme.colors?.black?.[7]}>{breadcrumbName || routeTo}</P2Medium>
      </Anchor>
    ) : (
      <Anchor key={routeTo} component={Link} to={routeTo}>
        <P2MediumUnderline color={theme.colors?.black?.[9]}>{breadcrumbName || routeTo}</P2MediumUnderline>
      </Anchor>
    );
  });

  return <Breadcrumbs separator="">{breadcrumbs.filter(Boolean)}</Breadcrumbs>;
};

export default DynamicBreadcrumbs;
