import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconMail, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import P1Medium from 'theme/components/Typography/P1Medium';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
  },
};

/**
 * Returns a Date object representing the start of a given time period.
 *
 * @param period - The time period to calculate, e.g. "Last week", "Last month", "Last 3 months", or "Last 6 months".
 * @returns A Date object, or a Date object representing the start of all time if `period` is not recognized.
 */
const calculateDateInterval = (period: string): Date => {
  const currentDate = new Date();
  switch (period) {
    case 'Last week':
      return new Date(currentDate.setDate(currentDate.getDate() - 7));
    case 'Last month':
      return new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    case 'Last 3 months':
      return new Date(currentDate.setMonth(currentDate.getMonth() - 3));
    case 'Last 6 months':
      return new Date(currentDate.setMonth(currentDate.getMonth() - 6));
    default:
      return new Date(0); // Default to all-time
  }
};
/**
 * A functional component that renders the main Wordpress BackupServers page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress BackupServers page.
 */
const BackupServers: React.FC = () => {
  const [rowData] = useState([
    {
      Title: 'Aquacity - Eshop',
      Size: '227.56 MB',
      Path: '/home/aquacity/public_html',
      LastBackup: '2024-08-03',
      Modified: '2024-08-03',
    },
    {
      Title: 'Aquacity - Eshop',
      Size: '227.56 MB',
      Path: '/home/aquacity/public_html',
      LastBackup: '2024-08-03',
      Modified: '2024-08-03',
    },
    {
      Title: 'Aquacity - Eshop',
      Size: '227.56 MB',
      Path: '/home/aquacity/public_html',
      LastBackup: '2024-08-03',
      Modified: '2024-08-03',
    },
    {
      Title: 'Aquacity - Eshop',
      Size: '227.56 MB',
      Path: '/home/aquacity/public_html',
      LastBackup: '2022-08-03',
      Modified: '2022-08-03',
    },
  ]);

  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [selectedLastBackup, setSelectedLastBackup] = useState<string | null>(null);
  const [selectedModified, setSelectedModified] = useState<string | null>(null);

  // Unique titles and customers for the select dropdowns
  const uniqueTitles = useMemo(() => {
    const titles = rowData.map((row) => row.Title);
    return [...new Set(titles)]; // Removing duplicates
  }, [rowData]);

  // Filtering the data based on selected filters
  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.Title.toLowerCase().includes(searchValue.toLowerCase());
      const matchesTitle = selectedTitle ? row.Title === selectedTitle : true;

      const matchesLastBackup = selectedLastBackup
        ? new Date(row.LastBackup) >= calculateDateInterval(selectedLastBackup)
        : true;

      const matchesModified = selectedModified
        ? new Date(row.Modified) >= calculateDateInterval(selectedModified)
        : true;

      return matchesSearch && matchesTitle && matchesLastBackup && matchesModified;
    });
  }, [searchValue, selectedTitle, selectedLastBackup, selectedModified, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'Title',
      headerName: 'Title',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <Badge variant="neutral" size="lg" leftSection={<IconMail size={16} />}>
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Size',
      headerName: 'Size',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <Badge variant="neutral" size="lg">
                Size: {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Path',
      headerName: 'Path',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'LastBackup',
      headerName: 'Last Backup',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <P1Medium>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Modified',
      headerName: 'Modified',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <P1Medium>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of servers</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>98 servers</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />

        <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
          <Select
            label="Title"
            placeholder="Pick value"
            data={uniqueTitles}
            value={selectedTitle}
            onChange={setSelectedTitle}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="LastBackup"
            placeholder="All"
            data={['Last week', 'Last month', 'Last 3 months', 'Last 6 months']}
            value={selectedLastBackup}
            onChange={setSelectedLastBackup}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="Modified"
            placeholder="All"
            data={['Last week', 'Last month', 'Last 3 months', 'Last 6 months']}
            value={selectedModified}
            onChange={setSelectedModified}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default BackupServers;
