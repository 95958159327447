import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconExternalLink, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import ActionsButtonsRenderer from 'components/ActionsButtonsRenderer';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import P3Semibold from 'theme/components/Typography/P3Semibold';
import { WP_PROJECTS_DETAILS_PATH } from 'routes/paths';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
    columnLimits: [
      {
        colId: 'Action',
        minWidth: 150,
      },
      {
        colId: 'Plugins',
        minWidth: 240,
      },
      {
        colId: 'PHP',
        minWidth: 120,
      },
      {
        colId: 'WP',
        minWidth: 120,
      },
      {
        colId: 'Techdebt',
        minWidth: 150,
      },
      {
        colId: 'Problems',
        minWidth: 70,
      },
    ],
  },
};

/**
 * A functional component that renders the main Wordpress projects page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress projects page.
 */
const Projects: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      Id: 0,
      Title: 'Project 1 asdsa adsdsa sa d assad sda sa sada sd asd as dasd as',
      Customer: 'Customer 1',
      Host: 'Host 1',
      WP: 'w6.0.6',
      PHP: 'p8.0.30',
      Plugins: 'Plugins 1',
      Techdebt: '90',
      Problems: 0,
    },
    {
      Id: 1,
      Title: 'Project 1',
      Customer: 'Customer 1',
      Host: 'Host 1',
      WP: 'w6.0.6',
      PHP: 'p8.0.30',
      Plugins: { major: 3, minor: 1 },
      Techdebt: '900',
      Problems: 10,
    },
    {
      Id: 2,
      Title: 'Project 1',
      Customer: 'Customer 1',
      Host: 'Host 1',
      WP: 'w6.0.6',
      PHP: 'p8.0.30',
      Plugins: 'Plugins 1',
      Techdebt: '35',
      Problems: 100,
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');
  const [selectedTitle, setSelectedTitle] = useState<string | null>(null);
  const [selectedCustomer, setSelectedCustomer] = useState<string | null>(null);
  const [selectedLastCheck, setSelectedLastCheck] = useState<string | null>('Last week');

  // Unique titles and customers for the select dropdowns
  const uniqueTitles = useMemo(() => {
    const titles = rowData.map((row) => row.Title);
    return [...new Set(titles)]; // Removing duplicates
  }, [rowData]);

  const uniqueCustomers = useMemo(() => {
    const customers = rowData.map((row) => row.Customer);
    return [...new Set(customers)]; // Removing duplicates
  }, [rowData]);

  // Filtering the data based on selected filters
  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.Title.toLowerCase().includes(searchValue.toLowerCase());
      const matchesTitle = selectedTitle ? row.Title === selectedTitle : true;
      const matchesCustomer = selectedCustomer ? row.Customer === selectedCustomer : true;

      // Implement your logic for "last check" filter
      // Assuming row.lastCheck is a Date object or can be compared with the selectedLastCheck

      return matchesSearch && matchesTitle && matchesCustomer;
    });
  }, [searchValue, selectedTitle, selectedCustomer, selectedLastCheck, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'Title',
      headerName: 'Title',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Customer',
      headerName: 'Customer',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Host',
      headerName: 'Host',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <IconExternalLink color={theme.colors?.black?.[8]} />
              <P1MediumUnderline color={theme.colors?.black?.[10]}>{params.value}</P1MediumUnderline>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'WP',
      headerName: 'WP',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'PHP',
      headerName: 'PHP',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'Plugins',
      headerName: 'Plugins',
      cellRenderer: (params: { value: any }) => {
        const { major, minor } = params.value; // Destructure major and minor from params.value

        return (
          <Flex align="center" h={72} gap={4}>
            {major !== undefined && (
              <Badge variant="red" size="lg" radius="md">
                MAJOR: {major}
              </Badge>
            )}
            {minor !== undefined && (
              <Badge variant="yellow" size="lg" radius="md">
                MINOR: {minor}
              </Badge>
            )}
          </Flex>
        );
      },
    },
    {
      field: 'Techdebt',
      headerName: 'Tech. debt',
      cellRenderer: (params: { value: any }) => {
        const timeInMinutes = params.value;

        // Convert time from minutes to hours and minutes
        const hours = Math.floor(timeInMinutes / 60);
        const minutes = timeInMinutes % 60;
        const formattedTime = `${hours > 0 ? `${hours}h ` : ''}${minutes}min.`;

        // Determine badge color based on time duration
        let color = '#10A84D'; // Default to green for < 60 minutes
        if (timeInMinutes > 360) {
          color = '#EE434E'; // Red for > 360 minutes
        } else if (timeInMinutes > 60) {
          color = '#CDBB1C'; // Orange for > 60 minutes
        }

        return (
          <Flex align="center" h={72} gap={4}>
            <Badge variant="dot" size="lg" radius="md" color={color}>
              <P1Medium>{formattedTime}</P1Medium>
            </Badge>
          </Flex>
        );
      },
    },
    {
      field: 'Problems',
      headerName: 'Problems',
      cellRenderer: (params: { value: any }) => {
        const problems = params.value;
        return (
          <Flex align="center" h={72} gap={4} justify="center">
            {problems !== 0 && (
              <div
                style={{
                  boxShadow: '0px 3px 8px 0px rgba(238, 67, 78, 0.25)',
                  width: '24px',
                  height: '24px',
                  backgroundColor: '#EE434E',
                  borderRadius: '50%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <P3Semibold color={theme.white}>{params.value}</P3Semibold>
              </div>
            )}
          </Flex>
        );
      },
    },
    {
      headerName: '',
      field: 'Action',
      width: 150,
      cellRenderer: (params: any) => {
        return <ActionsButtonsRenderer params={params} customPath={WP_PROJECTS_DETAILS_PATH.original} />;
      },
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>Wordpress projects</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>98 Projects</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />

        <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
          <Select
            label="Title"
            placeholder="Pick value"
            data={uniqueTitles}
            value={selectedTitle}
            onChange={setSelectedTitle}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="Customer"
            placeholder="Pick value"
            data={uniqueCustomers}
            value={selectedCustomer}
            onChange={setSelectedCustomer}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="Last check"
            placeholder="Pick value"
            data={['Last week', 'Last month', 'Last 3 months', 'Last 6 months']}
            value={selectedLastCheck}
            onChange={setSelectedLastCheck}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Projects;
