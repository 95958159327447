import React from 'react';
import { Button, Input, InputWrapper, PasswordInput } from '@mantine/core';
import { IconLock, IconMail } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

import theme from 'theme';
import SimpleLayout from 'layouts/SimpleLayout';
import { LOGIN_PAGE_GITLAB_PATH } from 'routes/paths';

/**
 * Sign in page
 * @param param0
 * @returns
 */
const signInClassic: React.FC = () => (
  <>
    <SimpleLayout>
      <InputWrapper id="input-email" variant="dark" required label="Email">
        <Input variant="dark" id="input-email" placeholder="Your email" leftSection={<IconMail size={24} />} />
      </InputWrapper>

      <InputWrapper id="input-email" variant="dark" required label="Password">
        <PasswordInput
          variant="dark"
          id="input-email"
          placeholder="Your password"
          leftSection={<IconLock size={24} />}
        />
      </InputWrapper>

      <Button mt={64}>Sign in</Button>
      <Link to={LOGIN_PAGE_GITLAB_PATH.original} style={{ color: theme.white, textAlign: 'center', paddingTop: '9px' }}>
        Sign in with Gitlab
      </Link>
    </SimpleLayout>
  </>
);
export default signInClassic;
