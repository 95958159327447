import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community';
import { Autocomplete, Badge, Box, Button, CloseButton, Divider, Flex } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconExternalLink, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import { DateInput } from '@mantine/dates';
import { InputWrapper } from '@mantine/core';
import P1Regular from 'theme/components/Typography/P1Regular';
import H5Semibold from 'theme/components/Typography/H5Semibold';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
  },
};

/**
 * Page component for displaying changelog for wordpress plugins.
 *
 * @remarks
 * This component is responsible for displaying changelog for wordpress plugins.
 * It uses ag-grid for displaying the changelog table and provides pagination.
 * The data is fetched from a static array and is filtered based on the selected project.
 */
const Changelog: React.FC = () => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [wordpressName, setWordpressName] = useState<string>('');
  const [showTable, setShowTable] = useState<boolean>(false);

  const initialRowData = [
    {
      wordpressName: 'Creanet Multisite - shop.holidaypark.sk',
      changelog: [
        {
          pluginName: 'Eventr Child Theme',
          changeType: '--',
          versionBeforeChange: '6.0.6',
          versionAfterChange: '6.0.7',
          changeDate: '2022-12-01 12:00:00',
        },
        {
          pluginName: 'Eventr Child Theme',
          changeType: '--',
          versionBeforeChange: '6.0.5',
          versionAfterChange: '6.0.6',
          changeDate: '2022-11-15 10:30:00',
        },
      ],
    },
    {
      wordpressName: 'AquaCity Eshop ASTRA',
      changelog: [
        {
          pluginName: 'plugin',
          changeType: 'Bug Fix',
          versionBeforeChange: '1.2.3',
          versionAfterChange: '1.2.4',
          changeDate: '2023-01-10 08:00:00',
        },
      ],
    },
  ];

  const [rowData, setRowData] = useState(initialRowData);
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage());
      setTotalPages(api.paginationGetTotalPages());
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  const isFormValid = useMemo(() => {
    return startDate !== null && endDate !== null && wordpressName.trim() !== '';
  }, [startDate, endDate, wordpressName]);

  /**
   * Shows the changelog table with the data of the selected project.
   *
   * @return {void} No return value.
   */
  const handleShowChangelog = () => {
    const selectedProject = initialRowData.find((project) => project.wordpressName === wordpressName);
    if (selectedProject) {
      const filteredChangelog = selectedProject.changelog.filter((log) => {
        const changeDate = new Date(log.changeDate);
        return changeDate >= startDate! && changeDate <= endDate!;
      });

      setRowData([{ wordpressName, changelog: filteredChangelog }]);
      setShowTable(true);
    }
  };

  /**
   * Resets the form and the grid to its initial state.
   *
   * @return {void} No return value.
   */
  const handleReset = () => {
    setStartDate(null);
    setEndDate(null);
    setWordpressName('');
    setShowTable(false);
    setRowData(initialRowData);
  };

  /**
   * Handles the selection of a new project from the Autocomplete.
   * Finds the selected project in the initial row data and sets the start and end dates
   * to the earliest and latest change dates of the selected project respectively.
   *
   * @param {string} value The value selected from the Autocomplete.
   * @return {void} No return value.
   */
  const handleProjectSelect = (value: string) => {
    setWordpressName(value);
    const selectedProject = initialRowData.find((project) => project.wordpressName === value);

    if (selectedProject) {
      const availableDates = selectedProject.changelog.map((log) => new Date(log.changeDate));
      const minDate = new Date(Math.min(...availableDates.map((date) => date.getTime())));
      const maxDate = new Date(Math.max(...availableDates.map((date) => date.getTime())));

      setStartDate(minDate);
      setEndDate(maxDate);
    }
  };

  const [colDefs] = useState<ColDef[]>([
    {
      field: 'pluginName',
      headerName: 'Plugin Name',
      cellRenderer: (params: { value: any }) => {
        return (
          <Flex align="center" h={72} gap={4}>
            <IconExternalLink color={theme.colors?.black?.[8]} />
            <P1MediumUnderline color={theme.colors?.black?.[10]}>{params.value}</P1MediumUnderline>
          </Flex>
        );
      },
    },
    {
      field: 'changeType',
      headerName: 'Change Type',
      cellRenderer: (params: { value: any }) => {
        return (
          <Flex align="center" h={72}>
            <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
          </Flex>
        );
      },
    },
    {
      field: 'versionBeforeChange',
      headerName: 'Version before change',
      cellRenderer: (params: { value: any }) => {
        return (
          <Flex align="center" h={72}>
            <Badge variant="neutral" size="lg">
              {params.value}
            </Badge>
          </Flex>
        );
      },
    },
    {
      field: 'versionAfterChange',
      headerName: 'Version after change',
      cellRenderer: (params: { value: any }) => {
        return (
          <Flex align="center" h={72}>
            <Badge variant="neutral" size="lg">
              {params.value}
            </Badge>
          </Flex>
        );
      },
    },
    {
      field: 'changeDate',
      headerName: 'Change date',
      cellRenderer: (params: { value: any }) => {
        return (
          <Flex align="center" h={72}>
            <P1Medium>{params.value}</P1Medium>
          </Flex>
        );
      },
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>Wordpress Plugin Changelog</H2Semibold>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} py={16} gap={16}>
        <InputWrapper label="Wordpress Name" w="100%" variant="dark">
          <Autocomplete
            placeholder="Hľadať..."
            value={wordpressName}
            size="md"
            onChange={handleProjectSelect}
            data={initialRowData.map((project) => project.wordpressName)}
            rightSectionPointerEvents="all"
            leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
            variant="solid"
            rightSection={
              <CloseButton
                aria-label="Clear input"
                onClick={() => setWordpressName('')}
                style={{ display: wordpressName ? undefined : 'none' }}
              />
            }
          />
        </InputWrapper>

        <DateInput
          variant="solid"
          size="md"
          value={startDate}
          onChange={setStartDate}
          label="Start date"
          placeholder="DD | MM | YYYY"
          w="100%"
          valueFormat="DD | MM | YYYY"
          disabled={!wordpressName}
        />
        <DateInput
          variant="solid"
          size="md"
          value={endDate}
          onChange={setEndDate}
          label="End date"
          placeholder="DD | MM | YYYY"
          w="100%"
          valueFormat="DD | MM | YYYY"
          disabled={!wordpressName}
        />
      </Flex>
      <Flex gap={10} pb={16}>
        <Button variant="primary" size="sm" disabled={!isFormValid || showTable} onClick={handleShowChangelog}>
          Show Plugin Changelog
        </Button>
        <Button variant="secondary" size="sm" disabled={!showTable} onClick={handleReset}>
          Reset
        </Button>
      </Flex>
      {showTable && (
        <>
          <Divider mt={32} />
          <Flex px={12} pt={24} pb={28} gap={40}>
            <Box>
              <P1Regular color={theme.colors?.black?.[7]}>Plugin changelog for:</P1Regular>
              <H5Semibold>{wordpressName}</H5Semibold>
            </Box>
            <Box>
              <P1Regular color={theme.colors?.black?.[7]}>Plugin changelog for:</P1Regular>
              <H5Semibold>
                {startDate?.toDateString()} - {endDate?.toDateString()}
              </H5Semibold>
            </Box>
          </Flex>
          <div className="ag-theme-quartz ag-theme-acmecorp">
            <AgGridReact
              ref={gridRef}
              rowData={rowData[0]?.changelog}
              columnDefs={colDefs}
              rowHeight={72}
              pagination={true}
              gridOptions={gridOptions}
              paginationPageSize={pageSize}
              domLayout="autoHeight"
              onPaginationChanged={() => {
                if (gridRef.current && gridRef.current.api) {
                  const api = gridRef.current.api;
                  setCurrentPage(api.paginationGetCurrentPage());
                  setTotalPages(api.paginationGetTotalPages());
                  setRowCount(api.paginationGetRowCount());
                }
              }}
            />

            <Flex
              justify="space-between"
              align="center"
              mt={16}
              style={{ borderTop: '1px solid #E1E2EA' }}
              pt={{ base: 16, sm: 0 }}
              direction={{ base: 'column', sm: 'row' }}
            >
              <Flex>
                <P1Medium color={theme.colors?.black?.[10]}>
                  Záznamy{' '}
                  <b>
                    {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
                  </b>{' '}
                  Celkom <b>{rowCount}</b>
                </P1Medium>
              </Flex>
              <Flex gap="sm" align="center" py="md">
                <IconChevronLeft
                  onClick={() => goToPage(Math.max(currentPage - 1, 0))}
                  style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
                  color={theme.colors?.black?.[10]}
                />
                {getDisplayedPages().map((page) => (
                  <Button
                    key={page}
                    size="sm"
                    variant="tertiary"
                    onClick={() => goToPage(page)}
                    style={{
                      backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                    }}
                  >
                    {page + 1}
                  </Button>
                ))}
                <IconChevronRight
                  onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
                  style={{
                    cursor: 'pointer',
                    opacity: currentPage === totalPages - 1 ? 0.5 : 1,
                  }}
                  color={theme.colors?.black?.[10]}
                />
              </Flex>
            </Flex>
          </div>
        </>
      )}
    </>
  );
};

export default Changelog;
