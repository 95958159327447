import { Grid, Flex, Badge, Divider, Button } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Regular from 'theme/components/Typography/P1Regular';
import P1Medium from 'theme/components/Typography/P1Medium';

/**
 * A functional component that displays a server hardware panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.id] - The unique identifier.
 * @param {string} [props.model] - The model name of the CPU.
 * @param {string} [props.cores] - The CPU core configuration.
 * @param {string} [props.frequency] - The CPU frequency in MHz.
 * @param {string} [props.ram] - The amount of RAM in GB.
 * @param {string} [props.swap] - The amount of swap memory in GB.
 * @return {JSX.Element} The server hardware panel component.
 */
const HardwarePanel = ({
  id = 1,
  model = 'Intel(R) Core(TM) i7-10510U CPU @ 1.80GHz',
  cores = '4/4',
  frequency = '2200',
  ram = '16 GB',
  swap = '4 GB',
}) => {
  return (
    <>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>CPU</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}></Grid.Col>

        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Regular>Model name</P1Regular>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <P1Medium>{model}</P1Medium>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Regular>Cores / Threads</P1Regular>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {cores}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Regular>Frequency</P1Regular>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {frequency}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Memory</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}></Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Regular>RAM</P1Regular>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {ram}
          </Badge>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Regular>SWAP</P1Regular>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Badge variant="neutral" size="lg">
            {swap}
          </Badge>
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default HardwarePanel;
