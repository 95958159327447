import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P2SemiboldUnderline as P2SemiboldUnderlineStyle } from 'theme/typography';

interface P2SemiboldUnderlineProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P2SemiboldUnderline typography style.
 */
const P2SemiboldUnderline: React.FC<P2SemiboldUnderlineProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P2SemiboldUnderlineStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P2SemiboldUnderline;
