import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P6Semibold as P6SemiboldStyle } from 'theme/typography';

interface P6SemiboldProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P6Semibold typography style.
 */
const P6Semibold: React.FC<P6SemiboldProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P6SemiboldStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P6Semibold;
