import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P2Semibold as P2SemiboldStyle } from 'theme/typography';

interface P2SemiboldProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P2Semibold typography style.
 */
const P2Semibold: React.FC<P2SemiboldProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P2SemiboldStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P2Semibold;
