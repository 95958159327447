import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { Badge, Box, Flex } from '@mantine/core';
import { IconCircleCheckFilled, IconCircleXFilled, IconDownload } from '@tabler/icons-react';
import { ColDef } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import theme from 'theme';
import P2Semibold from 'theme/components/Typography/P2Semibold';

// Define a TypeScript interface for your row data
interface RowData {
  name: string;
  slug: string;
  isActive: boolean;
  source: string;
  version: string;
  update: string;
}

/**
 * A functional component that renders a grid panel for displaying WordPress Plugins.
 *
 * @return {JSX.Element} The JSX element representing the Plugins panel.
 */
const PluginsPanel: React.FC = () => {
  // Define column definitions with the correct type
  const colDefs: ColDef<RowData>[] = [
    {
      headerName: 'Name',
      field: 'name',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },
    {
      headerName: 'Slug',
      field: 'slug',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },
    {
      headerName: 'Is Active',
      field: 'isActive',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value ? (
            <IconCircleCheckFilled style={{ color: '#10A84D' }} />
          ) : (
            <IconCircleXFilled style={{ color: '#9496B3' }} />
          )}
        </Flex>
      ),
    },
    {
      headerName: 'Source',
      field: 'source',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          <Badge variant="gray">{params.value}</Badge>
        </Flex>
      ),
    },
    {
      headerName: 'Version',
      field: 'version',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          <Badge variant="neutral">{params.value}</Badge>
        </Flex>
      ),
    },
    {
      headerName: 'Update',
      field: 'update',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value === 'UP TO DATE' ? (
            <Badge variant="green">{params.value}</Badge>
          ) : (
            <Badge variant="gray">{params.value}</Badge>
          )}
        </Flex>
      ),
    },
  ];

  // Define row data with the correct type
  const rowData: RowData[] = [
    {
      name: 'Confera',
      slug: 'confera',
      source: 'UNKNOWN',
      version: '1.0.4',
      isActive: true,
      update: 'UP TO DATE',
    },
    {
      name: 'Twenty Twenty-Three',
      slug: 'twentytwentythree',
      source: 'UNKNOWN',
      version: '1.2',
      isActive: false,
      update: 'UP TO DATE',
    },
    {
      name: 'Confera Child',
      slug: 'confera-child',
      source: 'UNKNOWN',
      version: '1.0.0',
      isActive: true,
      update: 'UNKNOWN',
    },
  ];

  return (
    <>
      <div className="ag-theme-quartz ag-theme-acmecorp" style={{ width: '100%', paddingTop: '16px' }}>
        <AgGridReact columnDefs={colDefs} rowData={rowData} rowHeight={56} domLayout="autoHeight" />
      </div>
      <div style={{ height: '1px', width: '100%', backgroundColor: theme.colors?.black?.[2] }}></div>
      <Box py={16} px={12}>
        <Link to="">
          <Flex gap={8}>
            <IconDownload color="#1D40F7" />
            <P2Semibold color="#1D40F7">Export data</P2Semibold>
          </Flex>
        </Link>
      </Box>
    </>
  );
};

export default PluginsPanel;
