import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { ActionIcon, Badge, Button, Flex } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconDots } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
    columnLimits: [
      {
        colId: 'Action',
        minWidth: 200,
        maxWidth: 200,
      },
    ],
  },
};

/**
 * A functional component that renders the main Wordpress versions page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress versions page.
 */
const Versions: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      version: '6.0.6',
      minPHP: 'p8.0.30',
      minSQL: 'w6.0.6',
      created: '2022-01-01',
    },
    {
      version: '6.0.6',
      minPHP: 'p8.0.30',
      minSQL: 'w6.0.6',
      created: '2022-01-01',
    },
    {
      version: '6.0.6',
      minPHP: 'p8.0.30',
      minSQL: 'w6.0.6',
      created: '2022-01-01',
    },
    {
      version: '6.0.6',
      minPHP: 'p8.0.30',
      minSQL: 'w6.0.6',
      created: '2022-01-01',
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'version',
      headerName: 'Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'minSQL',
      headerName: 'Min. PHP Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'minPHP',
      headerName: 'Min. MySQL Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'created',
      headerName: 'Created',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              {params.value}
            </Flex>
          </>
        );
      },
    },
    {
      headerName: '',
      field: 'Action',
      cellRenderer: (params: any) => {
        return (
          <Flex gap="sm" align="center" h={72}>
            <Button variant="secondary" size="sm">
              Download
            </Button>
            <ActionIcon variant="secondary">
              <IconDots />
            </ActionIcon>
          </Flex>
        );
      },
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" pb={20}>
        <H2Semibold>List of wordpress Versions</H2Semibold>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Versions;
