import { createTheme } from '@mantine/core';
import { components } from 'theme/components';

const theme = createTheme({
  fontFamily: 'Space Grotesk, sans-serif',
  primaryColor: 'blue',
  white: '#FFFFFF',
  black: '#000000',
  primaryShade: {
    light: 8,
  },
  // See https://www.figma.com/file/bG1Kr31FLx6rDhb1ke1vxQ/Creanet-Status-monitor?node-id=16%3A5241&mode=dev
  colors: {
    black: [
      '#F9F9FB', // Black 0
      '#F0F1F5', // Black 1
      '#E1E2EA', // Black 2
      '#CFD0DD', // Black 3
      '#B1B3C8', // Black 4
      '#9496B3', // Black 5
      '#7C7FA2', // Black 6
      '#5D6083', // Black 7
      '#44465F', // Black 8
      '#2F3042', // Black 9
      '#20212D', // Black 10
      '#171821', // Black 11
      '#111218', // Black 12
    ],
    blue: [
      '#F9F9FB', // Blue 0
      '#F0F3FE', // Blue 1
      '#E6EAFE', // Blue 2
      '#C4CDFD', // Blue 3
      '#7A8FFA', // Blue 4
      '#3F5DF8', // Blue 5
      '#1D40F7', // Blue 6
      '#082CE2', // Blue 7
      '#0723B6', // Blue 8
      '#051C94', // Blue 9
      '#041671', // Blue 10
    ],
  },
  components,
});

export default theme;
