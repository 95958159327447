import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';

import { ColDef, GridOptions } from 'ag-grid-community';
import { Badge, Button, Flex, Space } from '@mantine/core';
import P1Medium from 'theme/components/Typography/P1Medium';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
  },
};

/**
 * PackagePanel is a component that renders a data grid of packages with their
 * versions and upgrade versions. It also renders a pagination component that
 * allows the user to navigate through the pages of packages.
 *
 * @returns A JSX element that renders a data grid of packages with their
 * versions and upgrade versions.
 */
const PackagePanel: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      package: 'accountsservice',
      version: '0.6.55-0ubuntu12~20.04.5',
      upgradeVersion: '0.6.55-0ubuntu12~20.04.6',
    },
    {
      package: 'accountsservice',
      version: '0.6.55-0ubuntu12~20.04.5',
      upgradeVersion: '0.6.55-0ubuntu12~20.04.6',
    },
    {
      package: 'accountsservice',
      version: '0.6.55-0ubuntu12~20.04.5',
      upgradeVersion: '0.6.55-0ubuntu12~20.04.6',
    },
  ]);

  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage());
      setTotalPages(api.paginationGetTotalPages());
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'package',
      headerName: 'Package',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <P1Medium>{params.value}</P1Medium>
          </>
        );
      },
    },
    {
      field: 'version',
      headerName: 'Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'upgradeVersion',
      headerName: 'Upgrade Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Space h={16} />
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          {rowCount > 10 ? (
            <>
              <Flex>
                <P1Medium color="gray">
                  Records{' '}
                  <b>
                    {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
                  </b>{' '}
                  Total <b>{rowCount}</b>
                </P1Medium>
              </Flex>
              <Flex gap="sm" align="center" py="md">
                <IconChevronLeft
                  onClick={() => goToPage(Math.max(currentPage - 1, 0))}
                  style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
                  color="gray"
                />
                {getDisplayedPages().map((page) => (
                  <Button
                    key={page}
                    size="sm"
                    variant="tertiary"
                    onClick={() => goToPage(page)}
                    style={{
                      backgroundColor: currentPage === page ? '#E1E2EA' : 'transparent',
                    }}
                  >
                    {page + 1}
                  </Button>
                ))}
                <IconChevronRight
                  onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
                  style={{
                    cursor: 'pointer',
                    opacity: currentPage === totalPages - 1 ? 0.5 : 1,
                  }}
                  color="gray"
                />
              </Flex>
            </>
          ) : null}
        </Flex>
      </div>
    </>
  );
};

export default PackagePanel;
