import React from 'react';
import { Grid, Badge } from '@mantine/core';
import { IconCircleCheckFilled, IconCircleXFilled } from '@tabler/icons-react';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1Semibold from 'theme/components/Typography/P1Semibold';

interface Plugin {
  name: string;
  version: string;
  status: string; // or any other statuses you might have
  date: string;
}

interface PluginsTableProps {
  plugins: Plugin[];
}

/**
 *  A functional component that renders a table of plugins.
 *
 *  The table displays the name, version, status, and date of each plugin.
 *
 *  @param {PluginsTableProps} plugins - An array of plugin objects.
 *  @return {JSX.Element} A Grid component containing the plugin table.
 */
const PluginsTable: React.FC<PluginsTableProps> = ({ plugins }) => {
  return (
    <Grid style={{ marginTop: '16px', paddingLeft: '16px', paddingRight: '16px' }}>
      {plugins.map((plugin, index) => (
        <React.Fragment key={index}>
          <Grid.Col span={4} py={24}>
            <P1Semibold>{plugin.name}</P1Semibold>
          </Grid.Col>
          <Grid.Col span={2} py={24}>
            <Badge variant="default" color="gray" size="sm" radius="sm">
              {plugin.version}
            </Badge>
          </Grid.Col>
          <Grid.Col span={1} py={24}>
            {plugin.status === 'success' ? (
              <IconCircleCheckFilled size={24} style={{ color: '#10A84D' }} />
            ) : (
              <IconCircleXFilled size={24} style={{ color: '#9496B3' }} />
            )}
          </Grid.Col>
          <Grid.Col span={5} py={24}>
            <P1Medium>{plugin.date}</P1Medium>
          </Grid.Col>
        </React.Fragment>
      ))}
    </Grid>
  );
};

export default PluginsTable;
