import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input } from '@mantine/core';
import {
  IconChevronLeft,
  IconChevronRight,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconSearch,
} from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import ActionsButtonsRenderer from 'components/ActionsButtonsRenderer';
import { WP_PLUGINS_DETAILS_PATH } from 'routes/paths';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
    columnLimits: [],
  },
};

/**
 * A functional component that renders the main Wordpress Plugins page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Plugins page.
 */
const Plugins: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      id: 0,
      Title: 'ACF',
      slug: 'acf',
      projectCount: '5',
      version: '2.7.3',
      isUsed: true,
      source: 'UNKNOWN',
      lastModified: '2022-12-01 12:00:00',
    },
    {
      id: 1,
      Title: 'ACF',
      slug: 'acf',
      projectCount: '5',
      version: '2.7.3',
      isUsed: false,
      source: 'WORDPRESS',
      lastModified: '2022-12-01 12:00:00',
    },
    {
      id: 2,
      Title: 'ACF',
      slug: 'acf',
      projectCount: '5',
      version: '2.7.3',
      isUsed: true,
      source: 'UNKNOWN',
      lastModified: '2022-12-01 12:00:00',
    },
    {
      id: 3,
      Title: 'ACF Pro',
      slug: 'acf-pro',
      projectCount: '10',
      version: '3.1.1',
      isUsed: false,
      source: 'WORDPRESS',
      lastModified: '2022-11-15 14:30:00',
    },
    {
      id: 4,
      Title: 'ACF Extended',
      slug: 'acf-extended',
      projectCount: '8',
      version: '2.9.5',
      isUsed: true,
      source: 'UNKNOWN',
      lastModified: '2022-10-20 10:45:00',
    },
    {
      id: 5,
      Title: 'ACF Lite',
      slug: 'acf-lite',
      projectCount: '3',
      version: '1.8.2',
      isUsed: false,
      source: 'OTHER',
      lastModified: '2022-09-01 12:00:00',
    },
    {
      id: 6,
      Title: 'Themes Pro',
      slug: 'themes-pro',
      projectCount: '12',
      version: '4.2.1',
      isUsed: true,
      source: 'WORDPRESS',
      lastModified: '2022-11-25 16:15:00',
    },
    {
      id: 7,
      Title: 'Wallpaper HD',
      slug: 'wallpaper-hd',
      projectCount: '5',
      version: '2.5.3',
      isUsed: false,
      source: 'UNKNOWN',
      lastModified: '2022-10-10 11:30:00',
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');

  // Filtering the data based on selected filters
  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.Title.toLowerCase().includes(searchValue.toLowerCase());

      // Implement your logic for "last check" filter
      // Assuming row.lastCheck is a Date object or can be compared with the selectedLastCheck

      return matchesSearch;
    });
  }, [searchValue, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'Title',
      headerName: 'Title',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'slug',
      headerName: 'Slug',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Semibold color={theme.colors?.black?.[10]}>{params.value}</P1Semibold>
            </Flex>
          </>
        );
      },
    },

    {
      field: 'projectCount',
      headerName: 'Project Count',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}x
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'version',
      headerName: 'Current Version',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      headerName: 'Is used',
      field: 'isUsed',
      sortable: true,
      filter: true,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value ? (
            <IconCircleCheckFilled style={{ color: '#10A84D' }} />
          ) : (
            <IconCircleXFilled style={{ color: '#9496B3' }} />
          )}
        </Flex>
      ),
    },
    {
      field: 'source',
      headerName: 'Source',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              {params.value.toLowerCase() === 'unknown' ? (
                <Badge variant="gray" size="lg">
                  {params.value}
                </Badge>
              ) : (
                <Badge variant="blue" size="lg">
                  {params.value}
                </Badge>
              )}
            </Flex>
          </>
        );
      },
    },
    {
      headerName: '',
      field: 'Action',
      width: 150,
      cellRenderer: (params: any) => {
        return <ActionsButtonsRenderer params={params} customPath={WP_PLUGINS_DETAILS_PATH.original} />;
      },
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of wordpress Plugins</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>329 Plugins</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Plugins;
