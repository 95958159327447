import React from 'react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { Accordion, Badge, Blockquote, Flex, SimpleGrid, Table } from '@mantine/core';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import H4Semibold from 'theme/components/Typography/H4Semibold';
import AlertIcon from 'components/Icons/AlertIcon';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import P1Medium from 'theme/components/Typography/P1Medium';
import { IconMail } from '@tabler/icons-react';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';
import { Link } from 'react-router-dom';

/**
 * A functional component that renders the main Wordpress Problems page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Problems page.
 */
const Problems: React.FC = () => {
  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>Problems in Wordpress projects</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>98 problems</H3Medium>
      </Flex>
      <SimpleGrid pt={32} cols={{ base: 1, sm: 2 }}>
        <Accordion variant="separated" radius="md">
          <Accordion.Item key="outdated" value="outdated">
            <Accordion.Control icon={<AlertIcon />}>
              <H4Semibold>Outdated Creanet Status plugin</H4Semibold>
            </Accordion.Control>
            <Accordion.Panel pl={{ base: 12, sm: 56 }} pr={{ base: 12, sm: 24 }} py={24}>
              <Blockquote variant="gray">
                <P1Semibold>Rule:</P1Semibold>
                <P1Medium color={theme.colors?.black?.[8]}>
                  Every Wordpress project should contain an admin account for all of the emails:
                </P1Medium>
                <Flex pt={12} gap={4} wrap="wrap">
                  <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                    simkanic@creanet.sk
                  </Badge>
                  <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                    simkanic@creanet.sk
                  </Badge>
                  <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                    simkanic@creanet.sk
                  </Badge>
                </Flex>
              </Blockquote>
              <Blockquote variant="green" my={40}>
                <P1Semibold>Solutions:</P1Semibold>
                <P1Medium color={theme.colors?.black?.[8]}>
                  Someone with access to the project should create an account the emails.
                </P1Medium>
              </Blockquote>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Project</Table.Th>
                    <Table.Th>Missing accounts</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td>
                      <Link to="">
                        <P1MediumUnderline color={theme.colors?.black?.[10]}>SUNNY SIDE Apartments </P1MediumUnderline>
                      </Link>
                    </Table.Td>
                    <Table.Td>
                      <Flex gap={4} wrap="wrap">
                        <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                          simkanasasdasic@creanet.sk
                        </Badge>
                        <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                          asasddasdasasnic@creanet.sk
                        </Badge>
                        <Badge variant="neutral" leftSection={<IconMail size={16} />}>
                          simkanic@creanet.sk
                        </Badge>
                      </Flex>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>

        <Accordion variant="separated" radius="md">
          <Accordion.Item key="outdated" value="outdated">
            <Accordion.Control icon={<AlertIcon />}>
              <H4Semibold>Technical Debt Too High</H4Semibold>
            </Accordion.Control>
            <Accordion.Panel pl={{ base: 12, sm: 56 }} pr={{ base: 12, sm: 24 }} py={24}>
              <Blockquote variant="gray">
                <P1Semibold>Rule:</P1Semibold>
                <P1Medium color={theme.colors?.black?.[8]}>
                  Every Wordpress project should have low technical debt.
                </P1Medium>
              </Blockquote>
              <Blockquote variant="green" my={40}>
                <P1Semibold>Solutions:</P1Semibold>
                <P1Medium color={theme.colors?.black?.[8]}>Keep all plugins up to date.</P1Medium>
              </Blockquote>
              <Table>
                <Table.Thead>
                  <Table.Tr>
                    <Table.Th>Project</Table.Th>
                    <Table.Th>Technical Debt</Table.Th>
                  </Table.Tr>
                </Table.Thead>
                <Table.Tbody>
                  <Table.Tr>
                    <Table.Td style={{ whiteSpace: 'pre-wrap' }}>
                      <Link to="">
                        <P1MediumUnderline color={theme.colors?.black?.[10]}>SUNNY SIDE Apartments </P1MediumUnderline>
                      </Link>
                    </Table.Td>
                    <Table.Td>
                      <Badge variant="dot" color="red" size="xl">
                        8h 40m
                      </Badge>
                    </Table.Td>
                  </Table.Tr>
                </Table.Tbody>
              </Table>
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </SimpleGrid>
    </>
  );
};

export default Problems;
