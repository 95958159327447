import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconMail, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import P1Medium from 'theme/components/Typography/P1Medium';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
    columnLimits: [
      {
        colId: 'Action',
        minWidth: 150,
      },
      {
        colId: 'Plugins',
        minWidth: 240,
      },
      {
        colId: 'PHP',
        minWidth: 120,
      },
      {
        colId: 'WP',
        minWidth: 120,
      },
      {
        colId: 'Techdebt',
        minWidth: 150,
      },
      {
        colId: 'Problems',
        minWidth: 70,
      },
    ],
  },
};

/**
 * A functional component that renders the main Wordpress Users page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Users page.
 */
const Users: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      Id: 0,
      email: 'peter@gmail.com',
      projects: 5,
      lastModified: '2022-12-01 12:00:00',
    },
    {
      Id: 1,
      email: 'jano@gmail.com',
      projects: 10,
      lastModified: '2022-12-01 12:00:00',
    },
    {
      Id: 2,
      email: 'ondrej@gmail.com',
      projects: 15,
      lastModified: '2022-12-01 12:00:00',
    },
    {
      Id: 3,
      email: 'martin@gmail.com',
      projects: 63,
      lastModified: '2022-12-01 12:00:00',
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');
  const [selectedEmail, setSelectedEmail] = useState<string | null>(null);

  // Unique emails for the select dropdowns
  const uniqueEmails = useMemo(() => {
    const emails = rowData.map((row) => row.email);
    return [...new Set(emails)]; // Removing duplicates
  }, [rowData]);

  // Filtering the data based on selected filters
  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.email.toLowerCase().includes(searchValue.toLowerCase());
      const matchesEmail = selectedEmail ? row.email === selectedEmail : true;

      // Implement your logic for "last check" filter
      // Assuming row.lastCheck is a Date object or can be compared with the selectedLastCheck

      return matchesSearch && matchesEmail;
    });
  }, [searchValue, selectedEmail, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'email',
      headerName: 'Email',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <Badge variant="neutral" size="lg" leftSection={<IconMail size={16} />}>
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'projects',
      headerName: 'Projects',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <Badge variant="neutral" size="lg">
                {params.value}
              </Badge>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'lastModified',
      headerName: 'Last modified',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },

    {
      headerName: '',
      field: 'Action',
      width: 150,
      cellRenderer: (params: any) => {
        return (
          <Flex gap="sm" align="center" h={72}>
            <Button variant="secondary" size="sm">
              Show
            </Button>
          </Flex>
        );
      },
      resizable: false,
      sortable: false,
      filter: false,
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of wordpress Users</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>125 Users</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />

        <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
          <Select
            label="Email"
            placeholder="Pick value"
            data={uniqueEmails}
            value={selectedEmail}
            onChange={setSelectedEmail}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Users;
