import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { Box, Flex } from '@mantine/core';
import { IconDownload } from '@tabler/icons-react';
import { ColDef } from 'ag-grid-community';
import { Link } from 'react-router-dom';
import theme from 'theme';
import P2Semibold from 'theme/components/Typography/P2Semibold';

// Define a TypeScript interface for your row data
interface RowData {
  email: string;
  username: string;
  roles: string;
}

/**
 * A functional component that renders a grid panel for displaying WordPress Users.
 *
 * @return {JSX.Element} The JSX element representing the Users panel.
 */
const UsersPanel: React.FC = () => {
  // Define column definitions with the correct type
  const colDefs: ColDef<RowData>[] = [
    {
      headerName: 'Email',
      field: 'email',
      sortable: true,
      filter: true,
      width: 400,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },
    {
      headerName: 'Username',
      field: 'username',
      sortable: true,
      filter: true,
      width: 400,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },
    {
      headerName: 'Roles',
      field: 'roles',
      sortable: true,
      filter: true,
      width: 400,
      cellRenderer: (params: any) => (
        <Flex align="center" h={56}>
          {params.value}
        </Flex>
      ),
    },
  ];

  // Define row data with the correct type
  const rowData: RowData[] = [
    {
      email: 'adam@azet.com',
      username: 'adam',
      roles: 'administrator',
    },
    {
      email: 'adam@azet.com',
      username: 'matej',
      roles: 'administrator',
    },
    {
      email: 'adam@azet.com',
      username: 'peter',
      roles: 'administrator',
    },
    {
      email: 'adam@azet.com',
      username: 'peter',
      roles: 'administrator',
    },
  ];

  return (
    <>
      <div className="ag-theme-quartz ag-theme-acmecorp" style={{ width: '100%', paddingTop: '16px' }}>
        <AgGridReact columnDefs={colDefs} rowData={rowData} rowHeight={56} domLayout="autoHeight" />
      </div>
      <div style={{ height: '1px', width: '100%', backgroundColor: theme.colors?.black?.[2] }}></div>
      <Box py={16} px={12}>
        <Link to="">
          <Flex gap={8}>
            <IconDownload color="#1D40F7" />
            <P2Semibold color="#1D40F7">Export data</P2Semibold>
          </Flex>
        </Link>
      </Box>
    </>
  );
};

export default UsersPanel;
