import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef, GridOptions } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, CloseButton, Flex, Input, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight, IconExternalLink, IconSearch } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';

const gridOptions: GridOptions = {
  autoSizeStrategy: {
    type: 'fitGridWidth',
    defaultMinWidth: 50,
    columnLimits: [],
  },
};

/**
 * A functional component that renders the main Wordpress Themes page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Themes page.
 */
const Themes: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      Id: 0,
      theme: 'AquaCity Eshop ASTRA Child',
      slug: 'aqua-city-eshop-astra-child',
      project: 'AquaCity Eshop ASTRA',
      source: 'UNKNOWN',
      lastModified: '2022-12-01 12:00:00',
    },
    {
      Id: 1,
      theme: 'AquaCity Eshop ASTRA',
      slug: 'aqua-city-eshop-astra',
      project: 'AquaCity Eshop ASTRA',
      source: 'UNKNOWN',
      lastModified: '2022-12-01 12:00:00',
    },
    {
      Id: 2,
      theme: 'AquaCity Eshop ASTRA',
      slug: 'aqua-city-eshop-astra',
      project: 'AquaCity Eshop ASTRA',
      source: 'TRUST ME',
      lastModified: '2022-12-01 12:00:00',
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [searchValue, setSearchValue] = useState('');
  const [selectedTheme, setSelectedTheme] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<string | null>(null);

  // Unique themes and wp projects for the select dropdowns
  const uniqueThemes = useMemo(() => {
    const themes = rowData.map((row) => row.theme);
    return [...new Set(themes)]; // Removing duplicates
  }, [rowData]);

  const uniqueProjects = useMemo(() => {
    const projects = rowData.map((row) => row.project);
    return [...new Set(projects)]; // Removing duplicates
  }, [rowData]);

  // Filtering the data based on selected filters
  const filteredData = useMemo(() => {
    return rowData.filter((row) => {
      const matchesSearch = row.theme.toLowerCase().includes(searchValue.toLowerCase());
      const matchesTitle = selectedTheme ? row.theme === selectedTheme : true;
      const matchesProject = selectedProject ? row.project === selectedProject : true;

      return matchesSearch && matchesTitle && matchesProject;
    });
  }, [searchValue, selectedTheme, selectedProject, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'theme',
      headerName: 'Theme',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              <IconExternalLink color={theme.colors?.black?.[8]} />
              <P1MediumUnderline color={theme.colors?.black?.[10]}>{params.value}</P1MediumUnderline>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'slug',
      headerName: 'Slug',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'project',
      headerName: 'WP Projects',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
    {
      field: 'source',
      headerName: 'Source',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72} gap={4}>
              {params.value.toLowerCase() === 'unknown' ? (
                <Badge variant="gray" size="lg">
                  {params.value}
                </Badge>
              ) : (
                <Badge variant="blue" size="lg">
                  {params.value}
                </Badge>
              )}
            </Flex>
          </>
        );
      },
    },
    {
      field: 'lastModified',
      headerName: 'Last Modified',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of wordpress Themes</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>189 Themes</H3Medium>
      </Flex>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between" py={16} align="flex-end">
        <Input
          placeholder="Hľadať..."
          value={searchValue}
          onChange={(event) => setSearchValue(event.currentTarget.value)}
          rightSectionPointerEvents="all"
          leftSection={<IconSearch size={24} color={theme.colors?.black?.[6]} />}
          variant="solid"
          w={{ base: '100%', sm: 'auto' }}
          rightSection={
            <CloseButton
              aria-label="Clear input"
              onClick={() => setSearchValue('')}
              style={{ display: searchValue ? undefined : 'none' }}
            />
          }
        />

        <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }}>
          <Select
            label="Theme"
            placeholder="Alll Themes"
            data={uniqueThemes}
            value={selectedTheme}
            onChange={setSelectedTheme}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />

          <Select
            label="WP Projects"
            placeholder="All WP Projects"
            data={uniqueProjects}
            value={selectedProject}
            onChange={setSelectedProject}
            allowDeselect
            clearable
            variant="solid"
            searchable
          />
        </Flex>
      </Flex>
      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          gridOptions={gridOptions}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Themes;
