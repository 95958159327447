import { InputWrapper } from '@mantine/core';
import classNames from 'theme/components/Inputs/InputWrapper/InputWrapper.module.css';

export default InputWrapper.extend({
  classNames,
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
