import { PasswordInput } from '@mantine/core';
import classNames from 'theme/components/Inputs/PasswordInput/PasswordInput.module.css';

export default PasswordInput.extend({
  classNames,
  defaultProps: {
    variant: 'primary',
    size: 'md',
  },
});
