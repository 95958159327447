import { Flex, Button, Grid, Input, Divider } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconChevronLeft, IconEye } from '@tabler/icons-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { WP_PROJECTS_DETAILS_PATH } from 'routes/paths';
//import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import P1Semibold from 'theme/components/Typography/P1Semibold';

/**
 * A functional component that displays the Edit of a project based on its ID.
 *
 * @return {JSX.Element} The JSX element representing the project Edit page.
 */
const ProjectEdit: React.FC = () => {
  //const { id } = useParams<{ id: string }>(); // Access the ID from the URL
  const isSmallScreen = useMediaQuery('(max-width: 768px)');
  const navigate = useNavigate();

  return (
    <>
      <Flex justify="space-between" pb={16} gap={16} direction={{ base: 'column', md: 'row' }}>
        <Flex align="center" gap={8}>
          <IconChevronLeft
            size={30}
            color={theme.colors?.black?.[10]}
            strokeWidth={1.5}
            onClick={() => window.history.back()}
          />
          <H2Semibold color={theme.colors?.black?.[10]}>500 km slovenských</H2Semibold>
        </Flex>
        <Flex gap={8}>
          <Button
            variant="tertiary"
            size={isSmallScreen ? 'xs' : 'sm'}
            leftSection={<IconEye />}
            onClick={() => navigate(WP_PROJECTS_DETAILS_PATH.original.replace(':id', '1'))}
          >
            Show details project
          </Button>
          <Button variant="tertiary" size={isSmallScreen ? 'xs' : 'sm'}>
            Delete
          </Button>
        </Flex>
      </Flex>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Title</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input variant="outline" size="md"></Input>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Tags</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input variant="outline" size="md"></Input>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Admin URL</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input variant="outline" size="md"></Input>
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default ProjectEdit;
