import React from 'react';
import { SimpleGrid } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';
import theme from 'theme';
import H3Semibold from 'theme/components/Typography/H3Semibold';
import PluginsTable from 'components/PluginsTable';

const pluginsData = [
  {
    name: '301 Redirects',
    version: '2.7.3',
    status: 'success',
    date: '2023-04-25 23:00:11.649784',
  },
  {
    name: '301 Redirects',
    version: '2.7.3',
    status: 'failed',
    date: '2023-04-25 23:00:11.649784',
  },
  // Add more plugin objects here as needed
];

/**
 * A functional component that renders the main dashboard page.
 *
 * @return {JSX.Element} The JSX element representing the dashboard page.
 */
const DashboardPage: React.FC = () => {
  return (
    <>
      <SimpleGrid
        cols={{ base: 1, sm: 2, lg: 5 }}
        spacing={{ base: 10, sm: 'lg' }}
        verticalSpacing={{ base: 'md', sm: 'xl' }}
        pb={16}
      >
        <div style={{ borderRadius: '8px', backgroundColor: theme.colors?.black?.[1], padding: '24px' }}>
          <text style={{ fontSize: '44px', lineHeight: '44px' }}>420</text>
          <P1Semibold>Wordpress Projects</P1Semibold>
        </div>
        <div style={{ borderRadius: '8px', backgroundColor: theme.colors?.black?.[1], padding: '24px' }}>
          <text style={{ fontSize: '44px', lineHeight: '44px' }}>52</text>
          <P1Semibold>Wordpress Projects</P1Semibold>
        </div>
        <div style={{ borderRadius: '8px', backgroundColor: theme.colors?.black?.[1], padding: '24px' }}>
          <text style={{ fontSize: '44px', lineHeight: '44px' }}>65</text>
          <P1Semibold>Wordpress Projects</P1Semibold>
        </div>
        <div style={{ borderRadius: '8px', backgroundColor: theme.colors?.black?.[1], padding: '24px' }}>
          <text style={{ fontSize: '44px', lineHeight: '44px' }}>22</text>
          <P1Semibold>Wordpress Projects</P1Semibold>
        </div>
        <div style={{ borderRadius: '8px', backgroundColor: theme.colors?.black?.[1], padding: '24px' }}>
          <text style={{ fontSize: '44px', lineHeight: '44px' }}>3</text>
          <P1Semibold>Wordpress Projects</P1Semibold>
        </div>
      </SimpleGrid>
      <SimpleGrid cols={{ base: 1, sm: 2 }} spacing={{ base: 10, sm: 'xl' }} verticalSpacing={{ base: 'md', sm: 'xl' }}>
        <div>
          <div style={{ backgroundColor: theme.colors?.black?.[1], padding: '16px', borderRadius: '8px' }}>
            <H3Semibold>Plugins</H3Semibold>
          </div>
          <PluginsTable plugins={pluginsData} />
        </div>
        <div>
          <div style={{ backgroundColor: theme.colors?.black?.[1], padding: '16px', borderRadius: '8px' }}>
            <H3Semibold>Plugins</H3Semibold>
          </div>
        </div>
        <div>
          <div style={{ backgroundColor: theme.colors?.black?.[1], padding: '16px', borderRadius: '8px' }}>
            <H3Semibold>Plugins</H3Semibold>
          </div>
        </div>
        <div>
          <div style={{ backgroundColor: theme.colors?.black?.[1], padding: '16px', borderRadius: '8px' }}>
            <H3Semibold>Plugins</H3Semibold>
          </div>
        </div>
      </SimpleGrid>
    </>
  );
};

export default DashboardPage;
