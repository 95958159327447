import React from 'react';
import { MantineProvider } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { BrowserRouter } from 'react-router-dom';
import { Notifications } from '@mantine/notifications';
import AppRoutes from 'routes/AppRoutes';
import theme from 'theme';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@mantine/core/styles.css';

/**
 * The entry point of the application.
 */
export default function App() {
  return (
    <React.StrictMode>
      <BrowserRouter>
        <MantineProvider withCssVariables theme={theme}>
          <Notifications autoClose={8000} />
          <ModalsProvider>
            <AppRoutes />
          </ModalsProvider>
        </MantineProvider>
      </BrowserRouter>
    </React.StrictMode>
  );
}
