import { Flex, Button, Tabs } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import {
  IconArrowUpRight,
  IconChevronLeft,
  IconCpu,
  IconDisc,
  IconInfoCircle,
  IconKey,
  IconServer,
  IconShieldLock,
  IconX,
} from '@tabler/icons-react';
import React from 'react';
//import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';

import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import ServerPanel from 'pages/vps/vpsServers/ServerPanel';
import HardwarePanel from 'pages/vps/vpsServers/HardwarePanel';
import SystemPanel from 'pages/vps/vpsServers/SystemPanel';
import DisksPanel from 'pages/vps/vpsServers/DisksPanel';
import SshInfoPanel from 'pages/vps/vpsServers/SshInfoPanel';
import KeysPanel from 'pages/vps/vpsServers/KeysPanel';
import PackagePanel from 'pages/vps/vpsServers/PackagePanel';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

/**
 * A functional component that displays the details of a server based on its ID.
 *
 * @return {JSX.Element} The JSX element representing the server details page.
 */
const ServerDetails: React.FC = () => {
  //const { id } = useParams<{ id: string }>(); // Access the ID from the URL
  const isSmallScreen = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <Flex justify="space-between" pb={16} gap={16} direction={{ base: 'column', md: 'row' }}>
        <Flex align="center" gap={8}>
          <IconChevronLeft
            size={30}
            color={theme.colors?.black?.[10]}
            strokeWidth={1.5}
            onClick={() => window.history.back()}
          />
          <H2Semibold color={theme.colors?.black?.[10]}>Server Detail - 500 km slovenských</H2Semibold>
        </Flex>
        <Button variant="tertiary" size={isSmallScreen ? 'xs' : 'sm'} leftSection={<IconX />}>
          Delete
        </Button>
      </Flex>
      <Tabs color={theme.colors?.black?.[10]} defaultValue="info">
        <Tabs.List>
          <Tabs.Tab value="server" leftSection={<IconServer />}>
            Server
          </Tabs.Tab>
          <Tabs.Tab value="hardwareInfo" leftSection={<IconInfoCircle />}>
            Hardware info
          </Tabs.Tab>
          <Tabs.Tab value="systemInfo" leftSection={<IconCpu />}>
            System info
          </Tabs.Tab>
          <Tabs.Tab value="disks" leftSection={<IconDisc />}>
            Disks
          </Tabs.Tab>
          <Tabs.Tab value="sshInfo" leftSection={<IconShieldLock />}>
            SSH info
          </Tabs.Tab>
          <Tabs.Tab value="userKeys" leftSection={<IconKey />}>
            User keys
          </Tabs.Tab>
          <Tabs.Tab value="packageUpgrades" leftSection={<IconArrowUpRight />}>
            Package upgrades
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="server">
          <ServerPanel />
        </Tabs.Panel>

        <Tabs.Panel value="hardwareInfo">
          <HardwarePanel />
        </Tabs.Panel>

        <Tabs.Panel value="systemInfo">
          <SystemPanel />
        </Tabs.Panel>

        <Tabs.Panel value="disks">
          <DisksPanel />
        </Tabs.Panel>

        <Tabs.Panel value="sshInfo">
          <SshInfoPanel />
        </Tabs.Panel>

        <Tabs.Panel value="userKeys">
          <KeysPanel />
        </Tabs.Panel>

        <Tabs.Panel value="packageUpgrades">
          <PackagePanel />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default ServerDetails;
