import { Grid, Flex, Divider, Button, SegmentedControl } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';

/**
 * A functional component that displays a server hardware panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.id] - The unique identifier.
 * @param {string} [props.model] - The model name of the CPU.
 * @param {string} [props.cores] - The CPU core configuration.
 * @param {string} [props.frequency] - The CPU frequency in MHz.
 * @param {string} [props.ram] - The amount of RAM in GB.
 * @param {string} [props.swap] - The amount of swap memory in GB.
 * @return {JSX.Element} The server hardware panel component.
 */
const SshKeyPanel = ({
  uuid = '1eec223f7a903c69',
  title = 'www@patria.rtvs.sk',
  type = 'Server',
  status = 'Active',
  fingerPrint = 'd4530fd761dd9909d272f0245fb188a69745ea8d4f4ddf55c00d7057e8adbe44',
  publicKey = 'ssh-rsa AAAAB3NzaC1yc2EAAAADAQABAAABAQDQKFyMqTc0V2oZ/YCirJIgoUrktV4niMWEuaXJd4ippO6GsB9VMxRKvrGc/a3kKOmjXBG4JffK/zwzku2KpLyXbIBXruHFu1Gg6hU7iXX452Rb3Ls+bHcdGk7kcgZga8SafJXseLZwQ5Wu5k/w5S0ZGvtG8MLtOKphsGCfrUeKTZW0ZsCGkf03yH88/3Qru2XO+f/ZQbkCbPmwCiM6h8jylMcKtXRupP980Sfm7rTLcbCCdvruNC/34+O13Xlah/eZxljlHFc6WBXdOVDebofCyRUvW8Na30YMuDmdj6DXHz26mSSKMoo+a+i5J7RzZ7ay4AsepgyAc8R4Ms3li+tz www@patria.rtvs.sk',
}) => {
  return (
    <>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>UUID</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          {uuid}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Title</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          {title}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Type</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <SegmentedControl size="md" value={type} data={['Server', 'User', 'External User', 'Unknown']} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Status</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <SegmentedControl
            size="md"
            value={status}
            data={['Active', 'Old (to remove)', 'Banned (to remove ASAP)', 'Unknown']}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Fingerprint</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          {fingerPrint}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>Public Key</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16} style={{ wordBreak: 'break-all' }}>
          {publicKey}
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default SshKeyPanel;
