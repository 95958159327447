import { MantineThemeOverride } from '@mantine/core';

// Buttons
import Button from 'theme/components/Buttons/Button/Button';
import ActionIcon from 'theme/components/Buttons/ActionIcon/ActionIcon';
//Inputs
import Input from 'theme/components/Inputs/Input/Input';
import InputWrapper from 'theme/components/Inputs/InputWrapper/InputWrapper';
import DateInput from 'theme/components/Inputs/DateInput/DateInput';
import PasswordInput from 'theme/components/Inputs/PasswordInput/PasswordInput';
import Menu from 'theme/components/Overlays/Menu/Menu';
import Select from 'theme/components/Combobox/Select/Select';
import Badge from 'theme/components/DataDisplay/Badge/Badge';
import Table from 'theme/components/Typography/Table/Table';
import Accordion from 'theme/components/DataDisplay/Accordion/Accordion';
import Blockquote from 'theme/components/Typography/Blockquote/Blockquote';
import Pill from 'theme/components/Combobox/Pill/Pill';
import Modal from 'theme/components/Overlays/Modal/Modal';
import Checkbox from 'theme/components/Inputs/Checkbox/Checkbox';
import SegmentedControl from 'theme/components/Inputs/SegmentedControl/SegmentedControl';

export const components: MantineThemeOverride['components'] = {
  // Buttons
  Button,
  ActionIcon,
  //Inputs
  Input,
  Checkbox,
  InputWrapper,
  PasswordInput,
  DateInput,
  SegmentedControl,
  //Overlays
  Menu,
  Modal,
  //ComboBox
  Pill,
  Select,
  // Data Display
  Accordion,
  Badge,
  //Typography
  Blockquote,
  Table,

  // TODO: Add other components
};
