import SignInGitlab from 'pages/signIn/signInGitlab';
import SignInClassic from 'pages/signIn/signInClassic';
import Dashboard from 'pages/dashboard/DashboardPage';
import { Route, Routes } from 'react-router-dom';
import Projects from 'pages/wordpress/wordpressProjects/Projects';
import ProjectDetails from 'pages/wordpress/wordpressProjects/ProjectDetails';
import Versions from 'pages/wordpress/wordpressVersions/Versions';
import DashboardLayout from 'layouts/DashboardLayout';
import Plugins from 'pages/wordpress/wordpressPlugins/Plugins';
import Themes from 'pages/wordpress/wordpressThemes/Themes';
import Changelog from 'pages/wordpress/wordpressChangelog/Changelog';
import Compare from 'pages/wordpress/wordpressCompare/Compare';
import Logs from 'pages/wordpress/wordpressLogs/Logs';
import Users from 'pages/wordpress/wordpressUsers/Users';
import Problems from 'pages/wordpress/wordpressProblems/Problems';
import Servers from 'pages/vps/vpsServers/Servers';
import Sshkey from 'pages/vps/vpsSshkey/Sshkey';
import VpsProblems from 'pages/vps/vpsProblems/Problems';
import ServerDetails from 'pages/vps/vpsServers/ServerDetails';
import PluginDetails from 'pages/wordpress/wordpressPlugins/PluginDetails';
import ProjectEdit from 'pages/wordpress/wordpressProjects/ProjectEdit';
import SshKeyDetails from 'pages/vps/vpsSshkey/SshKeyDetails';
import BackupServers from 'pages/backups/backupServers/BackupServers';
import BackupDatabases from 'pages/backups/backupDatabases/BackupDatabases';
import {
  // Profile module
  LOGIN_PAGE_CLASSIC_PATH,
  LOGIN_PAGE_GITLAB_PATH,
  DASHBOARD_PATH,
  WP_PROJECTS_PATH,
  WP_VERSIONS_PATH,
  WP_PLUGINS_PATH,
  WP_THEMES_PATH,
  WP_CHANGELOG_PATH,
  WP_COMPARE_PATH,
  WP_LOGS_PATH,
  WP_PROBLEMS_PATH,
  WP_USERS_PATH,
  VPS_SERVERS_PATH,
  VPS_SSHKEY_PATH,
  VPS_PROBLEMS_PATH,
  WP_PROJECTS_DETAILS_PATH,
  VPS_SERVERS_DETAILS_PATH,
  WP_PLUGINS_DETAILS_PATH,
  WP_PROJECTS_EDIT_PATH,
  VPS_SSHKEY_DETAILS_PATH,
  BACKUPS_SERVERS_PATH,
  BACKUPS_DATABASES_PATH,
} from 'routes/paths';

/**
 * All routes in the app must be registered here.
 */
export default function AppRoutes() {
  return (
    <Routes>
      {/* Auth */}
      <Route path={LOGIN_PAGE_CLASSIC_PATH.original} element={<SignInClassic />} />
      <Route path={LOGIN_PAGE_GITLAB_PATH.original} element={<SignInGitlab />} />
      {/* Layout */}
      <Route path="/" element={<DashboardLayout />}>
        {/* Dashboard */}
        <Route path={DASHBOARD_PATH.original} element={<Dashboard />} />
        {/*Wordpress */}
        {/*Projects*/}
        <Route path={WP_PROJECTS_PATH.original} element={<Projects />} />
        <Route path={WP_PROJECTS_DETAILS_PATH.original} element={<ProjectDetails />} />
        <Route path={WP_PROJECTS_EDIT_PATH.original} element={<ProjectEdit />} />
        {/*Versions*/}
        <Route path={WP_VERSIONS_PATH.original} element={<Versions />} />
        {/*Plugins*/}
        <Route path={WP_PLUGINS_PATH.original} element={<Plugins />} />
        <Route path={WP_PLUGINS_DETAILS_PATH.original} element={<PluginDetails />} />
        {/*Themes*/}
        <Route path={WP_THEMES_PATH.original} element={<Themes />} />
        {/*Users*/}
        <Route path={WP_USERS_PATH.original} element={<Users />} />
        {/*Problems*/}
        <Route path={WP_PROBLEMS_PATH.original} element={<Problems />} />
        {/*Wordpress log*/}
        <Route path={WP_LOGS_PATH.original} element={<Logs />} />
        {/*Plugin changelog*/}
        <Route path={WP_CHANGELOG_PATH.original} element={<Changelog />} />
        {/*Plugin Compare*/}
        <Route path={WP_COMPARE_PATH.original} element={<Compare />} />

        {/*VPS*/}
        {/*Servers*/}
        <Route path={VPS_SERVERS_PATH.original} element={<Servers />} />
        <Route path={VPS_SERVERS_DETAILS_PATH.original} element={<ServerDetails />} />
        {/*SSH key*/}
        <Route path={VPS_SSHKEY_PATH.original} element={<Sshkey />} />
        <Route path={VPS_SSHKEY_DETAILS_PATH.original} element={<SshKeyDetails />} />
        {/*Problems*/}
        <Route path={VPS_PROBLEMS_PATH.original} element={<VpsProblems />} />

        {/*Backups*/}
        <Route path={BACKUPS_SERVERS_PATH.original} element={<BackupServers />} />
        {/*Databases*/}
        <Route path={BACKUPS_DATABASES_PATH.original} element={<BackupDatabases />} />
      </Route>
    </Routes>
  );
}
