// SimpleLayout.tsx

import React, { ReactNode } from 'react';
import { Flex, Stack, Image } from '@mantine/core';
import P2Semibold from 'theme/components/Typography/P2Semibold';
import P6Semibold from 'theme/components/Typography/P6Semibold';
import P2SemiboldUnderline from 'theme/components/Typography/P2SemiboldUnderline';
import theme from 'theme';

interface SimpleLayoutProps {
  children: ReactNode;
}

/**
 *  Simple layout for sign in page
 * @param param0
 * @returns
 */
const SimpleLayout: React.FC<SimpleLayoutProps> = ({ children }) => (
  <Flex
    direction="column"
    align="center"
    justify="center"
    style={{ backgroundColor: theme.colors?.black?.[10], height: '100vh' }}
  >
    <Stack
      w={384}
      bg={theme.colors?.black?.[11]}
      p={24}
      style={{ borderRadius: '8px', borderBottom: '4px solid #111218' }}
    >
      <Flex direction="row" justify="space-between">
        <Image src="../img/logo.png"></Image>
        <Flex direction="column">
          <P6Semibold color={theme.colors?.black?.[5]}>POWERED BY</P6Semibold>
          <P2Semibold color={theme.white} mt={-8}>
            CREANET
          </P2Semibold>
        </Flex>
      </Flex>
      {children}
    </Stack>

    <Flex direction="row" style={{ color: theme.white, position: 'absolute', bottom: '12px' }}>
      <P2Semibold>Created with</P2Semibold>
      <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
        <path
          clipRule="evenodd"
          d="M8.99976 6H7.00024V7.99951V7.99993V9.99945H8.99976H9.00018H10.9997V7.99993V7.99951V6H9.00018H8.99976ZM14.9998 6H16.9995V7.99951V7.99993V9.99945H14.9998H14.9996H13V10.0005H14.9996H14.9998H16.9995V11.9998V12.0004V13.9997H14.9998V15.9994H12.9998V15.9992H11.0001V7.99951H12.9998V6H14.9996H14.9998ZM8.99976 10.0005H7.00024V11.9998V12.0004V13.9997H8.99976V15.9994H10.9997V13.9997V13.9995V12.0004V11.9998V10.0005H9.00018H8.99976ZM11.0001 16.0005H13V18.0004H11.0001V16.0005ZM5 7.99951H6.99993V9.99945H5V7.99951ZM16.9998 7.99951H18.9998V9.99945H16.9998V7.99951ZM5 10.0005H6.99993V12.0004H5V10.0005ZM16.9998 10.0005H18.9998V12.0004H16.9998V10.0005Z"
          fill="#2384F4"
          fillRule="evenodd"
        />
      </svg>
      <P2Semibold>by</P2Semibold>
      <P2SemiboldUnderline pl={5}> CREANET</P2SemiboldUnderline>
    </Flex>
  </Flex>
);

export default SimpleLayout;
