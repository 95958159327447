import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P2MediumUnderline as P2MediumUnderlineStyle } from 'theme/typography';

interface P2MediumUnderlineProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P2MediumUnderline typography style.
 */
const P2MediumUnderline: React.FC<P2MediumUnderlineProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P2MediumUnderlineStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P2MediumUnderline;
