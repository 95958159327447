import { Flex, Tabs } from '@mantine/core';
import { IconCheck, IconChevronLeft, IconShieldLock } from '@tabler/icons-react';
import React from 'react';
//import { useParams } from 'react-router-dom';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';

import { Chart as ChartJS, LineElement, PointElement, LinearScale, Title, CategoryScale } from 'chart.js';
import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import SshKeyPanel from 'pages/vps/vpsSshkey/SshKeyPanel';
import UsagePanel from 'pages/vps/vpsSshkey/UsagePanel';

ChartJS.register(LineElement, PointElement, LinearScale, Title, CategoryScale);

/**
 * A functional component that displays the details of a project based on its ID.
 *
 * @return {JSX.Element} The JSX element representing the project details page.
 */
const SshKeyDetails: React.FC = () => {
  //const { id } = useParams<{ id: string }>(); // Access the ID from the URL

  return (
    <>
      <Flex justify="space-between" pb={16} gap={16} direction={{ base: 'column', md: 'row' }}>
        <Flex align="center" gap={8}>
          <IconChevronLeft
            size={30}
            color={theme.colors?.black?.[10]}
            strokeWidth={1.5}
            onClick={() => window.history.back()}
          />
          <H2Semibold color={theme.colors?.black?.[10]}>SSH Key Details</H2Semibold>
        </Flex>
      </Flex>
      <Tabs color={theme.colors?.black?.[10]} defaultValue="sshkey">
        <Tabs.List>
          <Tabs.Tab value="sshkey" leftSection={<IconShieldLock />}>
            SSH Key
          </Tabs.Tab>
          <Tabs.Tab value="usage" leftSection={<IconCheck />}>
            Usage
          </Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="sshkey">
          <SshKeyPanel />
        </Tabs.Panel>

        <Tabs.Panel value="usage">
          <UsagePanel />
        </Tabs.Panel>
      </Tabs>
    </>
  );
};

export default SshKeyDetails;
