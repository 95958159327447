import React from 'react';
import { ActionIcon, Button, Flex } from '@mantine/core';
import { IconDots } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';

interface ActionsButtonsRendererProps {
  params: any; // Adjust this type based on your data structure if necessary
  customPath: string; // Optional prop to override the default path
  buttonText?: string; // Optional prop to change the button text
  showActionIcon?: boolean; // Optional prop to control the visibility of the ActionIcon
}

/**
 * A React functional component that renders a cell action with a button and an action icon.
 *
 * @param {ActionsButtonsRendererProps} props - The props for the cell action, including the data, custom path, and optional configurations.
 * @return {JSX.Element} The JSX element representing the cell action.
 */
const ActionsButtonsRenderer: React.FC<ActionsButtonsRendererProps> = ({
  params,
  customPath,
  buttonText = 'Show', // Default text is 'Show' if not provided
  showActionIcon = true, // Default is to show the ActionIcon if not provided
}) => {
  const navigate = useNavigate();

  /**
   * Handles the click event on the show button by navigating to the detail page of the record.
   *
   * @return {void} No return value, navigates to the detail page instead.
   */
  const handleShowClick = () => {
    const recordId = params.data.Title; // Assuming the ID is stored in params.data.Id
    const path = customPath.replace(':id', recordId); // Use customPath if provided
    navigate(path); // Navigate to the detail page with the record ID
  };

  return (
    <Flex gap="sm" align="center" h={72}>
      <Button variant="secondary" size="sm" onClick={handleShowClick}>
        {buttonText}
      </Button>
      {showActionIcon && (
        <ActionIcon variant="secondary">
          <IconDots />
        </ActionIcon>
      )}
    </Flex>
  );
};

export default ActionsButtonsRenderer;
