import React from 'react';
import { Text, TextProps } from '@mantine/core';
import { P3Semibold as P3SemiboldStyle } from 'theme/typography';

interface P3SemiboldProps extends TextProps {
  customStyle?: React.CSSProperties;
  children: React.ReactNode; // Explicitly define the children prop
}

/**
 * Applies the P3Semibold typography style.
 */
const P3Semibold: React.FC<P3SemiboldProps> = ({ children, customStyle, ...restProps }) => {
  return (
    <Text {...restProps} style={{ ...P3SemiboldStyle, ...customStyle }}>
      {children}
    </Text>
  );
};

export default P3Semibold;
