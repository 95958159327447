import { Grid, Flex, Divider, Button, Input } from '@mantine/core';
import P1Semibold from 'theme/components/Typography/P1Semibold';

/**
 * A functional component that displays a server hardware panel with various details.
 *
 * @param {Object} props - The component props.
 * @param {number} [props.id] - The unique identifier.
 * @param {string} [props.model] - The model name of the CPU.
 * @param {string} [props.cores] - The CPU core configuration.
 * @param {string} [props.frequency] - The CPU frequency in MHz.
 * @param {string} [props.ram] - The amount of RAM in GB.
 * @param {string} [props.swap] - The amount of swap memory in GB.
 * @return {JSX.Element} The server hardware panel component.
 */
const SshInfoPanel = ({ id = 1, sshHost = '95.111.233.273', sshPort = '22', sshNote = 'ssh -p 22 95.111.233.273' }) => {
  return (
    <>
      <Grid py={16} w={{ base: '100%', md: '70%' }}>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>IP address</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input value={sshHost} variant="outline" size="md"></Input>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>IP address</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input value={sshPort} variant="outline" size="md"></Input>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 5 }} py={16}>
          <P1Semibold>IP address</P1Semibold>
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }} py={16}>
          <Input value={sshNote} variant="outline" size="md"></Input>
        </Grid.Col>
      </Grid>
      <Divider />
      <Flex gap={10} py={16}>
        <Button variant="primary">Save</Button>
        <Button variant="secondary">Cancel</Button>
      </Flex>
    </>
  );
};

export default SshInfoPanel;
