import React, { useEffect, useMemo, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react'; // React Data Grid Component
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid

import 'pages/wordpress/wordpressProjects/ag-grid-styles.css';
import { ColDef } from 'ag-grid-community'; // Import the correct type for ColDef
import { Badge, Button, Flex, Select } from '@mantine/core';
import { IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import theme from 'theme';
import H2Semibold from 'theme/components/Typography/H2Semibold';
import H3Medium from 'theme/components/Typography/H3Medium';
import P1Medium from 'theme/components/Typography/P1Medium';
import P1MediumUnderline from 'theme/components/Typography/P1MediumUnderline';

/**
 * A functional component that renders the main Wordpress Logs page.
 *
 * @return {JSX.Element} The JSX element representing the Wordpress Logs page.
 */
const Logs: React.FC = () => {
  // Row Data: The data to be displayed.
  const [rowData] = useState([
    {
      datetime: '2024-07-01 12:00:00',
      message: {
        type: 'Plugin version available',
        version: '1.0.0',
        message: 'Product Feed PRO for WooCommerce (woo-product-feed-pro) ',
      },
    },
  ]);
  /**AG GRID SETTINGS */
  const gridRef = useRef<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [rowCount, setRowCount] = useState(0);
  const pageSize = 9;

  useEffect(() => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      setRowCount(api.paginationGetRowCount());
      setTotalPages(api.paginationGetTotalPages());
      setCurrentPage(api.paginationGetCurrentPage());
    }
  }, [rowData]);

  /**
   * Navigates to a specific page in the paginated grid.
   *
   * @param {number} page - The page number to navigate to.
   * @return {void} No return value.
   */
  const goToPage = (page: number) => {
    if (gridRef.current && gridRef.current.api) {
      const api = gridRef.current.api;
      api.paginationGoToPage(page);
      setCurrentPage(api.paginationGetCurrentPage()); // Update current page after navigation
      setTotalPages(api.paginationGetTotalPages()); // Ensure total pages is correctly updated
    }
  };

  /**
   * Returns an array of page numbers that are currently displayed in the paginated grid.
   *
   * @return {number[]} An array of page numbers.
   */
  const getDisplayedPages = () => {
    return Array.from({ length: totalPages }, (_, i) => i);
  };

  /**SEARCH BARS SETTINGS */
  const [selectedLastCheck, setSelectedLastCheck] = useState<string | null>('All');

  /**
   * Helper function to parse datetime strings to Date objects.
   *
   * @param {string} dateString - The datetime string to be parsed.
   * @return {Date} The parsed Date object.
   */
  const parseDate = (dateString: string) => {
    return new Date(dateString);
  };

  //**FILTERING DATA */
  const filteredData = useMemo(() => {
    const now = new Date();
    return rowData.filter((row) => {
      const rowDate = parseDate(row.datetime);

      if (selectedLastCheck === 'Last week') {
        const lastWeek = new Date(now);
        lastWeek.setDate(now.getDate() - 7);
        return rowDate >= lastWeek;
      }

      if (selectedLastCheck === 'Last month') {
        const lastMonth = new Date(now);
        lastMonth.setMonth(now.getMonth() - 1);
        return rowDate >= lastMonth;
      }

      if (selectedLastCheck === 'Last 3 months') {
        const lastThreeMonths = new Date(now);
        lastThreeMonths.setMonth(now.getMonth() - 3);
        return rowDate >= lastThreeMonths;
      }

      // Default case: "All"
      return true;
    });
  }, [selectedLastCheck, rowData]);

  // Column Definitions: Defines the columns to be displayed.
  const [colDefs] = useState<ColDef[]>([
    {
      field: 'datetime',
      headerName: 'Datetime',
      cellRenderer: (params: { value: any }) => {
        return (
          <>
            <Flex align="center" h={72}>
              <P1Medium color={theme.colors?.black?.[10]}>{params.value}</P1Medium>
            </Flex>
          </>
        );
      },
    },

    {
      field: 'message',
      headerName: 'Message',
      wrapText: true,
      minWidth: 850,
      cellRenderer: (params: { value: any }) => (
        <Flex align="center" h={72} gap={12}>
          <Badge variant="green" size="lg">
            {params.value.type}
          </Badge>
          <Badge variant="neutral" size="lg">
            {params.value.version}
          </Badge>
          <P1MediumUnderline>{params.value.message}</P1MediumUnderline>
        </Flex>
      ),
    },
  ]);

  return (
    <>
      <Flex direction={{ base: 'column', sm: 'row' }} justify="space-between">
        <H2Semibold>List of Wordpress logs</H2Semibold>
        <H3Medium color={theme.colors?.black?.[5]}>11 logs</H3Medium>
      </Flex>

      <Flex gap="lg" direction={{ base: 'column', sm: 'row' }} w={{ base: '100%', sm: 'auto' }} py={16}>
        <Select
          label="Datetime"
          placeholder="All"
          data={['Last week', 'Last month', 'Last 3 months', 'All']}
          value={selectedLastCheck}
          onChange={setSelectedLastCheck}
          allowDeselect
          clearable
          variant="solid"
          searchable
        />
      </Flex>

      <div className="ag-theme-quartz ag-theme-acmecorp">
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={colDefs}
          rowHeight={72}
          pagination={true}
          paginationPageSize={pageSize}
          domLayout="autoHeight"
          onPaginationChanged={() => {
            if (gridRef.current && gridRef.current.api) {
              const api = gridRef.current.api;
              setCurrentPage(api.paginationGetCurrentPage());
              setTotalPages(api.paginationGetTotalPages());
              setRowCount(api.paginationGetRowCount());
            }
          }}
        />

        <Flex
          justify="space-between"
          align="center"
          mt={16}
          style={{ borderTop: '1px solid #E1E2EA' }}
          pt={{ base: 16, sm: 0 }}
          direction={{ base: 'column', sm: 'row' }}
        >
          <Flex>
            <P1Medium color={theme.colors?.black?.[10]}>
              Záznamy{' '}
              <b>
                {currentPage * pageSize + 1}/{Math.min((currentPage + 1) * pageSize, rowCount)}
              </b>{' '}
              Celkom <b>{rowCount}</b>
            </P1Medium>
          </Flex>
          <Flex gap="sm" align="center" py="md">
            <IconChevronLeft
              onClick={() => goToPage(Math.max(currentPage - 1, 0))}
              style={{ cursor: 'pointer', opacity: currentPage === 0 ? 0.5 : 1 }}
              color={theme.colors?.black?.[10]}
            />
            {getDisplayedPages().map((page) => (
              <Button
                key={page}
                size="sm"
                variant="tertiary"
                onClick={() => goToPage(page)}
                style={{
                  backgroundColor: currentPage === page ? theme.colors?.black?.[0] : 'transparent',
                }}
              >
                {page + 1}
              </Button>
            ))}
            <IconChevronRight
              onClick={() => goToPage(Math.min(currentPage + 1, totalPages - 1))}
              style={{
                cursor: 'pointer',
                opacity: currentPage === totalPages - 1 ? 0.5 : 1,
              }}
              color={theme.colors?.black?.[10]}
            />
          </Flex>
        </Flex>
      </div>
    </>
  );
};

export default Logs;
