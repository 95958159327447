import React, { useState } from 'react';
import { AppShell, Box, Burger, Collapse, Menu, Space } from '@mantine/core';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import {
  IconBrandSpeedtest,
  IconBrandWordpress,
  IconChevronDown,
  IconChevronRight,
  IconDatabase,
  IconPower,
  IconServer,
} from '@tabler/icons-react';
import { Logo } from 'components/Icons/Logo';
import theme from 'theme';
import {
  WP_CHANGELOG_PATH,
  WP_COMPARE_PATH,
  DASHBOARD_PATH,
  LOGIN_PAGE_GITLAB_PATH,
  WP_LOGS_PATH,
  WP_PLUGINS_PATH,
  WP_PROBLEMS_PATH,
  WP_PROJECTS_PATH,
  WP_THEMES_PATH,
  WP_USERS_PATH,
  WP_VERSIONS_PATH,
  VPS_SERVERS_PATH,
  VPS_SSHKEY_PATH,
  VPS_PROBLEMS_PATH,
  BACKUPS_SERVERS_PATH,
  BACKUPS_DATABASES_PATH,
} from 'routes/paths';
import P4Medium from 'theme/components/Typography/P4Medium';
import DynamicBreadcrumbs from 'components/navigation/DynamicBreadcrumbs'; // Import the dynamic breadcrumbs component
import { Outlet } from 'react-router-dom';
import P1Medium from 'theme/components/Typography/P1Medium';

const menuItems = [
  {
    title: 'Dashboard',
    icon: <IconBrandSpeedtest size={24} color="white" />,
    link: DASHBOARD_PATH.original,
  },
  {
    title: 'Wordpress',
    icon: <IconBrandWordpress size={24} color="white" />,
    children: [
      { title: 'Projects', link: WP_PROJECTS_PATH.original },
      { title: 'Versions', link: WP_VERSIONS_PATH.original },
      { title: 'Plugins', link: WP_PLUGINS_PATH.original },
      { title: 'Themes', link: WP_THEMES_PATH.original },
      { title: 'Users', link: WP_USERS_PATH.original },
      { title: 'Problems', link: WP_PROBLEMS_PATH.original },
      { title: 'Wodpress log', link: WP_LOGS_PATH.original },
      { title: 'Plugin changelog', link: WP_CHANGELOG_PATH.original },
      { title: 'Plugin Compare', link: WP_COMPARE_PATH.original },
    ],
  },
  {
    title: 'VPS',
    icon: <IconServer size={24} color="white" />,
    children: [
      { title: 'Servers', link: VPS_SERVERS_PATH.original },
      { title: 'SSH key', link: VPS_SSHKEY_PATH.original },
      { title: 'Problems', link: VPS_PROBLEMS_PATH.original },
    ],
  },
  {
    title: 'Backups',
    icon: <IconDatabase size={24} color="white" />,
    children: [
      { title: 'Databases', link: BACKUPS_DATABASES_PATH.original },
      { title: 'Servers', link: BACKUPS_SERVERS_PATH.original },
    ],
  },
];

/**
 * A React functional component that renders the dashboard layout.
 *
 * @param {React.ReactNode} children - The content to be rendered within the dashboard layout.
 * @return {JSX.Element} The dashboard layout component.
 */
const DashboardLayout: React.FC = () => {
  const [opened, { toggle }] = useDisclosure();
  const [openedMenu, setOpenedMenu] = useState<number | null>(null);
  const isLargeScreen = useMediaQuery('(min-width: 1024px)');

  /**
   *  Toggles the opened menu based on the provided index.
   *
   *  @param {number} index - The index of the menu to toggle.
   *  @return {void} No return value.
   */
  const toggleMenu = (index: number): void => {
    setOpenedMenu(openedMenu === index ? null : index);
  };

  return (
    <AppShell
      header={{ height: 60, collapsed: isLargeScreen }}
      navbar={{ width: 217, breakpoint: 'sm', collapsed: { mobile: !opened } }}
      padding="md"
    >
      <AppShell.Header p="md" bg={theme.colors?.black?.[10]} style={{ borderBottom: 'none' }}>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '100%' }}>
          <Logo />
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" color="white" />
        </div>
      </AppShell.Header>

      <AppShell.Navbar p="sm" bg={theme.colors?.black?.[10]} style={{ borderRight: 'none' }}>
        <Box p={10} hidden={!isLargeScreen}>
          <Logo />
        </Box>
        <Menu>
          {menuItems.map((item, index) => (
            <div key={index}>
              <Menu.Item
                py={12}
                px={16}
                mt={16}
                leftSection={item.icon}
                rightSection={
                  item.children ? (
                    openedMenu === index ? (
                      <IconChevronDown color={theme.colors?.black?.[5]} />
                    ) : (
                      <IconChevronRight color={theme.colors?.black?.[5]} />
                    )
                  ) : null
                }
                onClick={() => (item.children && toggleMenu(index)) || window.open(item.link, '_self')}
              >
                <P1Medium>{item.title}</P1Medium>
              </Menu.Item>
              {item.children && (
                <Collapse in={openedMenu === index}>
                  {item.children.map((child, childIndex) => (
                    <Menu.Item
                      key={childIndex}
                      py={12}
                      pl={50}
                      pr={16}
                      onClick={child.link ? () => window.open(child.link, '_self') : undefined}
                    >
                      <P1Medium>{child.title}</P1Medium>
                    </Menu.Item>
                  ))}
                </Collapse>
              )}
            </div>
          ))}
          <Box style={{ marginTop: 'auto' }}>
            <Menu.Item
              py={12}
              px={16}
              mt={16}
              leftSection={<IconPower size={24} />}
              onClick={() => window.open(LOGIN_PAGE_GITLAB_PATH.original, '_self')}
            >
              <P1Medium>Log out</P1Medium>
            </Menu.Item>
            <P4Medium px={16} pt={16} color={theme.colors?.black?.[5]}>
              Version 4.0.0 • Copyright © 2023 Creanet. All rights reserved.
            </P4Medium>
          </Box>
        </Menu>
      </AppShell.Navbar>

      <AppShell.Main>
        {/* Dynamic Breadcrumbs */}
        <DynamicBreadcrumbs />
        <Space h="md" />
        {/* Main content */}
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};

export default DashboardLayout;
